import { Spin, Button, Modal, Popconfirm, message } from "antd";

import { useContext, useEffect, useState, useCallback } from "react";
import { AppContext } from "../../context/app-context";
import { Resource, ResourceContent } from "../../models/countries-interface";
import Table, { ColumnsType } from "antd/es/table";
import EditResourcePage from "./EditResourcePage";
import { CountrySelect } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResourceApi } from "../../apis/resource-api";

import "./ResourcesPage.scss";

const ResourcesPage = () => {
  //english selected or first available
  const [ displayResources, setDisplayResources ] = useState<Resource[]>([]); 
  //all resources per language which makes sense
  const [ resourcesForSelectedLangs, setResourcesForSelectedLangs ] = useState<{[lang:string]: Resource[]}>();
  //do object
  const [ resourcesForAllLangs, setResourcesForAllLangs ] = useState<ResourceContent|null>();

  const [ resourceLength, setResourceLength ] = useState<number>(0);

  const { isLoadingCountry, selectedCountry, profile } = useContext(AppContext);
  const [ editingId, setEditingId ] = useState<string|null>(null);
  const [ isNewResource, setIsNewResource ] = useState<boolean>(false);

  const [ isLoadingResources, setLoadingResources ] = useState<boolean>(true);
  const [modal, modalContextHolder] = Modal.useModal();

  const loadResources = useCallback((c: string) => {
    if (!selectedCountry?.code.iv) {
	  return;
	}
	    
	setLoadingResources(true);

	ResourceApi.getResources(c)
	  .then((r) => {
		let rscs: {[lang:string]: Resource[]} = {};
		let missingPrimaryKeys: string[] = []; // stores any resource ids that do not exist in english
		let englishPKs = r.resources['en'].map(x => x.id);
		let tableData = [...r.resources['en']];

		selectedCountry.languages.iv.forEach((lang) => {
		  // append any resources that exist for other translations
		  missingPrimaryKeys = missingPrimaryKeys.concat(r.resources[lang.code].map(x => x.id).filter((item) => englishPKs.indexOf(item) < 0));
		  rscs[lang.code] = r.resources[lang.code]; 
		});

	    missingPrimaryKeys.forEach((id) => {
		  tableData.push({
			id,
			title: "N/A",
			resourceUrl: "No English Translation"
		  });
		});

		setDisplayResources(tableData);
		setResourcesForSelectedLangs(rscs);
	  	setResourcesForAllLangs(r);
	  })
	  .catch((ex) => {	
		setResourceLength(0);
		setDisplayResources([]);
		setResourcesForSelectedLangs({});
		setResourcesForAllLangs(null);

		if (ex.data && ex.data.includes("no data")) {
	      modal.error({
		    title: "Error",
			content: <p>Resources have not been setup for this market.</p>
	      });
		} else {
		  modal.error({
		    title: "Error",
			content: 
			  <>
				<p>Something went wrong while loading resources for this market.</p>
				<p className="light-text">{ex.data}</p>
		      </>
		  });
		}
	  })
	  .finally(() => {
		setLoadingResources(false);
	  });
  }, [selectedCountry, modal]);

  const refreshResources = useCallback(() => {
    if (selectedCountry?.code.iv && editingId === null) {
      loadResources(selectedCountry?.code.iv);
    } 
  }, [selectedCountry, editingId, loadResources]);

  useEffect(() => {
	refreshResources();
  }, [refreshResources]);

  const getNewResource = (id: string) => {
	return {id, title: "", resourceUrl: "", content: ""};
  };

  const onNewResource = () => {
	var newId = crypto.randomUUID();
	if (selectedCountry !== null && resourcesForSelectedLangs) {
	  for (let lang in resourcesForSelectedLangs) {
		resourcesForSelectedLangs[lang].push(getNewResource(newId));
	  }

	  setEditingId(newId);
	  setResourceLength(resourceLength+1);

	  setIsNewResource(true);
	}
  };

  const onLinkClick = (id: string) => {
	setIsNewResource(false);
    setEditingId(id);
  };

  const onDelete = (idToDelete: string, title: string) => {
	if (!resourcesForAllLangs) {
	  return;
	}

	setLoadingResources(true);

	const { id, ...updatedResources } = resourcesForAllLangs;

	for (let lang in resourcesForAllLangs.resources) {
	  if (updatedResources.resources && updatedResources.resources[lang]) {
        updatedResources.resources[lang] = resourcesForAllLangs.resources[lang]?.filter((x) => x.id !== idToDelete);
	  }
	}

	ResourceApi.updateResource(resourcesForAllLangs.id, updatedResources)
	  .then(() => {
	    refreshResources();
	    message.info(`Resource ${title ? `'${title}' ` : ''}has been deleted.`);
	  })
	  .catch(() => {
        message.error(`An error was encountered: Resource ${title ? `'${title}' ` : ''}has not been deleted.`);
	    setLoadingResources(false);
	  });
  };

  const saveResource = () => {
    setEditingId(null);
    setIsNewResource(false);
    if (selectedCountry?.code.iv) {
      loadResources(selectedCountry?.code.iv);
    }
  };

  const onCancelEdit = () => {
    setEditingId(null);
    setIsNewResource(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, record, index) => <Button type="link" onClick={() => {onLinkClick(record.id)}}>{title}</Button>,
    },
    {
      title: 'URL',
      dataIndex: 'resourceUrl',
      key: 'resourceUrl',
    },
    {
      title: null,
      dataIndex: 'delete',
      key: 'delete',
	  width: 150,
      render: (title, record, index) => {
		return (
	      <Popconfirm
		    okText="Yes"
		    cancelText="No"
		    title={
	          <div style={{"display": "flex", "flexDirection": "column"}}>
		        <span>{`You are about to permanently delete this resource from all Bright Sky databases.`}</span>
				<span>{`Are you sure you want to remove this resource?`}</span>
			  </div>
			}
		    onConfirm={() => {
		  	  onDelete(record.id, record.title)
	        }}
	        placement="left"
		  >
		    <Button
			  aria-label='Delete'
		      className="row-right"
			  type="default"
			  icon={<FontAwesomeIcon icon={['far', 'trash']} />}
		    >
			  {"Delete"}
		    </Button>
		  </Popconfirm>
		);
      },
    }
  ];

  return (
    <>
      { editingId === null && !isNewResource && 
		<>
		  <div className="content-header resources-header">
			<h2>{'Resources'}</h2>
			{ !isLoadingResources && (
			  <span>{`(${displayResources.length} Total Resources)`}</span>
			)}
			<span className="row-right"></span>
			{ profile?.market === "All" && (
			  <>
			    <span className="row-right">{'Country / Market:'}</span>
				<CountrySelect />
			  </>
			)}
			{modalContextHolder}
			<Button 
			  disabled={resourcesForAllLangs === null}
			  onClick={onNewResource}
		      type="primary" 
			  icon={<FontAwesomeIcon icon={['far', 'plus']} />}
			>
			  {'New Resource'}
			</Button>
	      </div>
          <div className="content-body resources-body">
            { isLoadingCountry && (
              <Spin />
            )}
            { !isLoadingCountry && displayResources && (
              <Table 
				className="resources-table"
				pagination={false}
				columns={columns} 
				dataSource={displayResources}
				loading={isLoadingResources}
				rowKey={(x) => x.id}
		      />
            )}
          </div>
        </>
	  }
      { editingId !== null && selectedCountry && resourcesForAllLangs &&
        <EditResourcePage 
          onCancel={onCancelEdit}
          onSave={saveResource}
          country={selectedCountry}
          allResources={resourcesForAllLangs}
	      resourceId={editingId}
		  isNewResource={isNewResource}
        />
      }
    </>
  );
}

export default ResourcesPage;
