import { Button, Spin, message, Popconfirm } from "antd";

import { useContext, useEffect, useState, useCallback } from "react";
import { AppContext } from "../../context/app-context";
import { Directory, HttpPostDirectory } from "../../models/countries-interface";
import EditServiceDirectoryPage from "./EditServiceDirectoryPage";
import { CountrySelect } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DirectoryApi } from "../../apis/directory-api";

import './ServiceDirectory.scss';
import './LocationResult.scss';

interface ServiceDirectoryPageProps {
  isNationalHelplines: boolean;
}

const ServiceDirectoryPage = ({isNationalHelplines}: ServiceDirectoryPageProps) => {
  const [directories, setDirectories] = useState<any[]>([]);
  const { selectedCountry, countries, setSelectedCountry, profile } = useContext(AppContext);
  const [ editingDirectory, setEditingDirectory ] = useState<HttpPostDirectory|null>(null);
  const [ isLoading, setLoading ] = useState<boolean>(true);

  const loadDirectories = useCallback((c: string) => {
    setLoading(true);
    if (profile?.market === "US") return;

	DirectoryApi.getServices(c, isNationalHelplines)
	  .then((services) => {
        setDirectories(services);
        setLoading(false);
	  })
  }, [setLoading, setDirectories, isNationalHelplines, profile]);

  const refreshDirectories = useCallback(() => {
    if (profile !== null && countries !== null && selectedCountry !== null) {
      if (profile.market === "All" && selectedCountry.code.iv === "US") {
	    setSelectedCountry(countries[0]);
	    loadDirectories(countries[0].code.iv);
	  } else {
	    loadDirectories(selectedCountry.code.iv);
	  }
    }
  }, [profile, selectedCountry, countries, loadDirectories, setSelectedCountry]);

  useEffect(() => {
    refreshDirectories();
  }, [refreshDirectories]);
    
  const onEdit = (directory: Directory) => {
    if (selectedCountry !== null) {
	  let err = false;
	  if (!directory.id) err = true;
	  if (!directory.country || directory.country.iv !== selectedCountry.code.iv) err = true;
	  if (!directory.isNationalHelpLine || directory.isNationalHelpLine.iv !== isNationalHelplines) err = true;

	  if (err) return;

	  let edit_dir = {
	    id: { iv: directory.id },
		isNationalHelpLine: { iv: isNationalHelplines },
		country: { iv: selectedCountry.code.iv },
		tags: { iv: directory.tags?.iv || [] },

		website: { iv: directory.website?.iv || "" },
		displayOnMap: { iv: directory.displayOnMap?.iv || false },
				
		order: { iv: directory.order?.iv || null },
		
		location: { iv: directory.location?.iv || { longitude: 0, latitude: 0 } },
		
		phoneNumbers: { iv: [...(directory.phoneNumbers?.iv || [])] },
		emailAddresses: { iv: [...(directory.emailAddresses?.iv || [])] },
		
		name: {...directory.name},
		hours: {...directory.hours},
		description: {...directory.description},
		address: {...directory.address},
	  };

	  for (let i = 0; i < selectedCountry.languages.iv.length; i++) {
	    let lang_code = selectedCountry.languages.iv[i].code;

		if (!edit_dir.name[lang_code]) edit_dir.name[lang_code] = "";
		if (!edit_dir.hours[lang_code]) edit_dir.hours[lang_code] = "";
		if (!edit_dir.description[lang_code]) edit_dir.description[lang_code] = "";
		if (!edit_dir.address[lang_code]) edit_dir.address[lang_code] = "";
	  }

	  setEditingDirectory(edit_dir);
	}
  };

  const onDelete = (idToDelete: string, serviceName: string) => {
	setLoading(true);

	if (!serviceName) {
	  serviceName = isNationalHelplines ? "Helpline" : "Service";
	}

    DirectoryApi.deleteService(idToDelete)
	  .then(() => {
	    refreshDirectories();
	    message.info(`${serviceName} has been deleted.`);
	  })
	  .catch(() => {
        message.error(`An error was encountered: ${serviceName} has not been deleted.`);
	    setLoading(false);
	  });
  };

  const onAddNew = () => {
    if (selectedCountry !== null) {
	  let empty_lang_fields:{ [lang: string]: string } = {};

	  for (let i = 0; i < selectedCountry.languages.iv.length; i++) {
	    empty_lang_fields[selectedCountry.languages.iv[i].code] = "";
	  }

	  setEditingDirectory({
	    id: {iv: null},
	    country: { iv: selectedCountry.code.iv },
	    tags: { iv: [] },
	    isNationalHelpLine: { iv: isNationalHelplines },
	    order: { iv: null },

	    name: {...empty_lang_fields},
	    hours: {...empty_lang_fields},
	    description: {...empty_lang_fields},
	    address: {...empty_lang_fields},

	    website: { iv: "" },
	    phoneNumbers: { iv: [] },
	    emailAddresses: { iv: [] },

	    location: { iv: { longitude: 0, latitude: 0 }},
	    displayOnMap: { iv: false },				
	  });
    }
  };

  const saveDirectory = () => {
    if (selectedCountry?.code.iv) {
      loadDirectories(selectedCountry?.code.iv);
    }
    setEditingDirectory(null);
  };

  const pageTitle = isNationalHelplines ? "National Helplines" : "Service Directory";

  if (profile?.market === "United States") return <></>;

  return (
	<>
      { !editingDirectory && 
		<>
          <div className="content-header">
		    <h2>{pageTitle}</h2>
			{ !isLoading && directories && <span>({directories.length} locations)</span>}
			<span className="row-right"></span>
			{ profile?.market === "All" && 
			  <>
			    <span>{'Country / Market:'}</span>
				<CountrySelect countryFilter={["United States"]}/>
			  </>
		    }
			<Button 
			  onClick={onAddNew}
			  type="primary" 
			  icon={<FontAwesomeIcon icon={['far', 'plus']} />}
			>
			  {`New ${isNationalHelplines ? "National Helpline" : "Service"}`}
			</Button>
		  </div>
		  <div className="content-body directory-body">
		  { isLoading && (
            <div className="center-box">
			  <Spin size="large" />
			</div>
          )}
		  { !isLoading && directories.map((r, i) => (
		    <div key={r.id} className="content-tile directory-tile">      
			  <div className="row directory-header">
				<div className="left">
				  <h2>{(r.name && r.name['en']) ? r.name['en'] : ""}</h2>
				  <div className="directory-desc" dangerouslySetInnerHTML={{ __html: (r.description && r.description['en']) ? r.description['en'] : "" }}></div>
				</div>	
				<div className="right">
	 			  <Button
				    aria-label='Edit'
				    onClick={() => onEdit(r)}
				    className="row-right"
				    type="default"
				    icon={<FontAwesomeIcon icon={['far', 'pen-to-square']} />}
				  >
				    {"Edit"}
				  </Button>
                  <Popconfirm
					okText="Yes"
				    cancelText="No"
				    title={
				      <div style={{"display": "flex", "flexDirection": "column"}}>
				        <span>{`You are about to permanently delete this ${isNationalHelplines ? "helpline" : "service"} from all Bright Sky databases.`}</span>
				        <span>{`Are you sure you want to remove this ${isNationalHelplines ? "helpline" : "service"}?`}</span>
					  </div>
					}
				    onConfirm={() => {
					  onDelete(r.id, r.name['en'])
					}}
				    placement="left"
				  >
				    <Button
				      aria-label='Delete'
				      className="row-right"
				      type="default"
				      icon={<FontAwesomeIcon icon={['far', 'trash']} />}
				    >
				      {"Delete"}
				    </Button>
				  </Popconfirm>
			    </div>
			  </div>

			  <div className="row directory-attr-row">
			    <>
				  { !isNationalHelplines && (
				    <div className="directory-attr">
				      <h3>{'Address:'}</h3>
					  <div className="content" dangerouslySetInnerHTML={{ __html: (r.address && r.address['en']) ? r.address['en'] : "" }}></div>
					</div>
				  )}
				</>
				<>
				  { isNationalHelplines && (
				    <div className="directory-attr">
					  <h3>{'Hours:'}</h3>
					  <div className="content" dangerouslySetInnerHTML={{ __html: (r.hours && r.hours['en']) ? r.hours['en'] : "" }}></div>
					</div>
				  )}
				</>
				<div className="directory-attr">
			      <h3>{'Phone:'}</h3>
				  <div className="content"><p>{r.primaryPhoneNumber ? r.primaryPhoneNumber : ""}</p></div>
			    </div>
				<>
				  { isNationalHelplines && (
				    <div className="directory-attr">
					  <h3>{'Email:'}</h3>
					  <div className="content"><p>{r.primaryEmailAddress ? r.primaryEmailAddress : ""}</p></div>
					</div>
				  )}
				</>
			  </div>                         
			</div>
          ))}
		</div>
      </>
    }
    { !isLoading && editingDirectory !== null && selectedCountry &&
      <EditServiceDirectoryPage 
        onCancel={() => { setEditingDirectory(null); }}
        onSave={saveDirectory}
        directory={editingDirectory}
        country={selectedCountry}
        isNationalHelplines={isNationalHelplines}
      />
    }
    </>
  );
}

export default ServiceDirectoryPage;

