import { EventTableDataType, GAReportResponse, GAReportResponseRow, PageViewTableDataType } from "../features/reports/ReportPage";

const GAEventNames: string[] = ["submit_entry_journal", "directory_search", "cal_999", "questionnaire_complete_myself",
  "website_directory_listing", "test_your_knowledge_completion", "phone_directory_listing", "questionnaire_complete_for_someone_else",
  "email_directory_listing", "feedback", "settings_change", "view_listing"];

export const mapGoogleAnalyticsEventNames = function (eventReport: GAReportResponse) {
  eventReport.rows = eventReport.rows.filter((x) => GAEventNames.includes(x.dimensionValues[0].value));
  eventReport.rows.map((row: GAReportResponseRow) => {
    var displayName = null;

    switch (row.dimensionValues[0].value) {
      case "submit_entry_journal":
        displayName = "Submitted Journal Entry"; break;
      case "directory_search":
        displayName = "Directory Search"; break;
      case "cal_999":
        displayName = "Pressed 'Call 999'"; break;
      case "questionnaire_complete_myself":
        displayName = "Completed Questionnaire (For Self)"; break;
      case "website_directory_listing":
        displayName = "Clicked 'Website' on Directory Listing"; break;
      case "test_your_knowledge_completion":
        displayName = "Completed 'Test Your Knowledge'"; break;
      case "phone_directory_listing":
        displayName = "Clicked 'Phone' on Directory Listing"; break;
      case "questionnaire_complete_for_someone_else":
        displayName = "Completed Questionnaire (For Someone Else)"; break;
      case "email_directory_listing":
        displayName = "Clicked 'Email' on Directory Listing"; break;
      case "feedback":
        displayName = "Submitted Feedback"; break;
      case "settings_change":
        displayName = "Changed Settings"; break;
      case "view_listing":
        displayName = "View Directory Listing"; break;
    }

    row.dimensionValues[0].displayName = displayName ?? undefined;
    return row;
  });

  return eventReport;
}

export const getEventTableData = function (eventReport: GAReportResponse) {
  var tableData: EventTableDataType[] = [];

  var indexOfEventCount = eventReport.metricHeaders.findIndex(x => x.name === "eventCount");
  var indexOfTotalUsers = eventReport.metricHeaders.findIndex(x => x.name === "totalUsers");
  var indexOfEventCountPerUser = eventReport.metricHeaders.findIndex(x => x.name === "eventCountPerUser");

  eventReport.rows.forEach((row: GAReportResponseRow, index: number) => {
    if (!row.dimensionValues[0].displayName) {
      return;
    }

    tableData.push({
      key: index,
      eventName: row.dimensionValues[0].displayName,
      eventCount: Math.round(parseFloat(row.metricValues[indexOfEventCount].value) * 100) / 100,
      totalUsers: Math.round(parseFloat(row.metricValues[indexOfTotalUsers].value) * 100) / 100,
      eventCountPerUser: Math.round(parseFloat(row.metricValues[indexOfEventCountPerUser].value) * 100) / 100,
    });
  });

  return tableData;
}

export const getPageViewTableData = function (pageViewReport: GAReportResponse) {
  var tableData: PageViewTableDataType[] = [];

  var indexOfPageViews = pageViewReport.metricHeaders.findIndex(x => x.name === "screenPageViews");
  var indexOfPageViewsPerUser = pageViewReport.metricHeaders.findIndex(x => x.name === "screenPageViewsPerUser");
  var indexOfTotalUsers = pageViewReport.metricHeaders.findIndex(x => x.name === "totalUsers");

  pageViewReport.rows.forEach((row: GAReportResponseRow, index: number) => {
    tableData.push({
      key: index,
      pageName: row.dimensionValues[0].value,
      screenPageViews: Math.round(parseFloat(row.metricValues[indexOfPageViews].value) * 100) / 100,
      screenPageViewsPerUser: Math.round(parseFloat(row.metricValues[indexOfPageViewsPerUser].value) * 100) / 100,
      totalUsers: Math.round(parseFloat(row.metricValues[indexOfTotalUsers].value) * 100) / 100,
    });
  });

  return tableData;
}

export const getFeedbackDimensionIndexes = function (feedbackReport: GAReportResponse) {
  var dimensionIndexes: number[] = [];

  dimensionIndexes.push(feedbackReport.dimensionHeaders.findIndex(x => x.name === "customEvent:EaseOfUseRating"));

  return dimensionIndexes;
}