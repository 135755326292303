import { HttpPostResourceContent, ResourceContent } from "../models/countries-interface";
import { api } from "../utils/api";

export const ResourceApi = {
	updateResource: async (id: string, resource: HttpPostResourceContent): Promise<void> => {
		await api.post(`Content/UpdateResources/${id}`, resource);
	},
	getResources: async (country_code: string):Promise<ResourceContent> => {
		return await api.post(`Content/Resources`, {country: country_code});
	},
	deleteResource: async (id: string): Promise<any> => {
        return await api.delete(`Content/${id}`);
    }
};