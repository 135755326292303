// RichTextEditor.tsx
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'code'],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false
  }
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'code',
];

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  id?: string;
  value: string | undefined;
  placeholder: string;
  onBlur?: () => void;
  onChange: OnChangeHandler;
};

const RichTextEditor: React.FC<Props> = ({ onBlur, id, value, onChange, placeholder }) => {
  return (
    <>
      <ReactQuill
	  	id={id}
        theme="snow"
        value={value || ''}
        modules={modules}
        formats={formats}
		onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
      />
    </>
  );
};

export default RichTextEditor;