import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretDown, faCaretUp, faCircle, faCircleXmark, faDatabase as faSolidDatabase, faHouse as faSolidHouse, faHeadset as faSolidHeadset, faCircleInfo as faSolidCircleInfo, faCircleQuestion as faSolidCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { faFolderGrid as faSolidFolderGrid } from '@fortawesome/pro-solid-svg-icons';
import { faDatabase as faOutlinedHouse, faHouse as faOutlinedDatabase, faHeadset as faOutlinedHeadset, faCircleInfo as faBorderCircleInfo, faFolderGrid as faOutlinedFolderGrid, faCircleQuestion as faOutlinedCircleQuestion, faFileWord, faPlus, faAngleLeft, faFloppyDisk, faPenToSquare, faExpand, faCompress, faTrash, faLocationDot } from '@fortawesome/pro-regular-svg-icons';

library.add(
	faCaretUp,
	faCaretDown,
	faAngleLeft,
	faCircleXmark,
	faFloppyDisk,

	faCircle,

	faFileWord,
	faPlus,
	faTrash,
	faPenToSquare,
	faExpand,
	faCompress,

	//maps
	faLocationDot,

	//side nav
	faSolidHouse,
	faOutlinedHouse,

	faSolidDatabase,
	faOutlinedDatabase,

	faSolidHeadset,
	faOutlinedHeadset,

	faSolidCircleInfo,
	faBorderCircleInfo,

	faSolidFolderGrid,
	faOutlinedFolderGrid,

	faSolidCircleQuestion,
	faOutlinedCircleQuestion,
);