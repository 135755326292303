import SideNav from '../side-nav/SideNav';
import './MainLayout.scss';
import TopNav from '../top-nav/TopNav';

const MainLayout = ({children}: any) => {

    return (
        <div className="layout">
            <div className="layout-header">
				<TopNav />
			</div>
			<div className="layout-body row">
				<SideNav />
				<div className="layout-content">
					{children}
				</div>
			</div>
        </div>
    );
};

export default MainLayout;