import { useCallback, useEffect, useMemo } from 'react';
import { Button, Card, DatePicker, Row, Table, TableColumnsType } from 'antd';
import useTokenContext from '../../components/shared/hooks/TokenContext';
import { useState } from 'react';
import { Report } from 'powerbi-report-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { httpPost, httpGet } from '../../common/api';
import { Tabs } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import { Loading } from '../../components/shared/Loading';
import { HestiaReportView } from './HestiaReportView';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { getEventTableData, getPageViewTableData, mapGoogleAnalyticsEventNames } from '../../utils/google-analytics-helper';

import './ReportPage.scss';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

type PowerBIReportResponse = {
  monthlyDownloads: number,
  monthlyDistinctActiveUsers: number,
  yearlyCumulativeActiveUsers: number,
  totalGlobalUsers: number,
  totalDirectLivesImproved: number,
  totalIndirectLivesImproved: number
};

type ReportType = "monthlyReport" | "yearlyReport" | "timePeriodReport" | "lifetimeReport" | "eventReport" | "pageViewReport" | "feedbackReport";

export type EventTableDataType = {
  key: React.Key;
  eventName: string;
  eventCount: number;
  totalUsers: number;
  eventCountPerUser: number;
};

export type PageViewTableDataType = {
  key: React.Key;
  pageName: string;
  screenPageViews: number;
  screenPageViewsPerUser: number;
  totalUsers: number;
};

type GABatchReportResponse = {
  reports: Record<ReportType, GAReportResponse>,
};

export type GAReportResponseRow = {
  dimensionValues: Array<{ displayName?: string, value: string, hasValue: boolean, oneValueCase: number }>, 
  metricValues: Array<{ value: string, hasValue: boolean, oneValueCase: number }>
};

export type GAReportResponse = {
  dimensionHeaders: Array<{ name: string, type: number }>,
  metricHeaders: Array<{ name: string, type: number }>,
  rows: Array<GAReportResponseRow>
};

const ReportPage = () => {
  const [authToken, setAuthToken] = useState<string>();
  const powerBiAuthToken = useTokenContext();

  const [isFullscreen, setFullscreen] = useState<boolean>(false);

  const [powerBiData, setPowerBiData] = useState<PowerBIReportResponse | null>(null);
  const [powerBiDataLoading, setPowerBiDataLoading] = useState<boolean>(false);
  const [powerBiDataError, setPowerBiDataError] = useState<boolean>(false);

  const [hestiaReport, setHestiaReport] = useState<GABatchReportResponse | null>(null);
  const [hestiaReportLoading, setHestiaReportLoading] = useState<boolean>(false);
  const [hestiaReportError, setHestiaReportError] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs("2024-03-04"));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const [consolidatedReportLoading, setConsolidatedReportLoading] = useState<boolean>(true);

  const setToken = (token: string) => {
	setAuthToken(token);
  }

  var extraSettings = {
    filterPaneEnabled: false,
	navContentPaneEnabled: true,
  };

  const pullHestiaData = useCallback(() => {
	setHestiaReportLoading(true);
    httpPost('Report/RunReport', { StartDate: startDate, EndDate: endDate }, null)
	  .then((res: GABatchReportResponse) => {
		res.reports.eventReport = mapGoogleAnalyticsEventNames(res.reports.eventReport);
	    setHestiaReport(res);
	    setHestiaReportLoading(false);
	  })
      .catch((error) => { console.log(error); setHestiaReportError(true); });
  }, [startDate, endDate]);

  useEffect(() => {
	pullHestiaData();
  }, [pullHestiaData]);

  const pullPowerBIData = useCallback(() => {
	setPowerBiDataLoading(true);
    httpGet('Report/GetPowerBIReport', null)
	  .then((res: PowerBIReportResponse) => {
	    setPowerBiData(res);
	    setPowerBiDataLoading(false);
	  })
      .catch((error) => { console.log(error); setPowerBiDataError(true); });
  }, []);

  useEffect(() => {
	pullPowerBIData();
  }, [pullPowerBIData]);

  useEffect(() => {
	if (!authToken) powerBiAuthToken.useInitializeToken(setToken);
  }, [authToken]);

  const toggleFullscreen = () => {
    if (document.fullscreenElement !== null) {
	  document.exitFullscreen();
	  setFullscreen(false);
	} else {
	  document.getElementById("report")?.requestFullscreen();
	  setFullscreen(true);
	}
  };

  const getMetricValue = (report: GAReportResponse | undefined, metricHeader: string): number => {
	if (!report) {
      return 0;
	}
	var metricValueIndex = report.metricHeaders.findIndex(x => x.name === metricHeader);
    return parseInt(report.rows.at(0)?.metricValues.at(metricValueIndex)?.value ?? "0");
  };

  const consolidatedReport = useMemo(() => {
	if (!powerBiData || !hestiaReport) {
	  return null;
	}
	
	setConsolidatedReportLoading(true);
	const report = {
	  MonthlyDownloads: powerBiData.monthlyDownloads + getMetricValue(hestiaReport?.reports.monthlyReport, "newUsers"),
	  MonthlyActiveUsers: powerBiData.monthlyDistinctActiveUsers + getMetricValue(hestiaReport?.reports.monthlyReport, "activeUsers"),
	  YearlyCumulativeActiveUsers: powerBiData.yearlyCumulativeActiveUsers + getMetricValue(hestiaReport?.reports.yearlyReport, "activeUsers"),
	  TotalGlobalUsers: powerBiData.totalGlobalUsers + getMetricValue(hestiaReport?.reports.lifetimeReport, "totalUsers"),
	  DirectLivesImproved: powerBiData.totalDirectLivesImproved + Math.round(getMetricValue(hestiaReport?.reports.lifetimeReport, "totalUsers") * 1.45),
	  IndirectLivesImproved: powerBiData.totalIndirectLivesImproved + Math.round(getMetricValue(hestiaReport?.reports.lifetimeReport, "totalUsers") * 1.45 * 2.32),
	};

	setConsolidatedReportLoading(false);
	return report;
  }, [powerBiData, hestiaReport, setConsolidatedReportLoading]);

  useEffect(() => {
    if (!consolidatedReport) { setConsolidatedReportLoading(true); }
  }, [consolidatedReport]);

  var eventColumns: TableColumnsType<EventTableDataType> = [
	{ title: 'Event Name', dataIndex: 'eventName', key: 'eventName' },
	{ title: 'Event Count', dataIndex: 'eventCount', key: 'eventCount' },
	{ title: 'Total Users', dataIndex: 'totalUsers', key: 'totalUsers' },
	{ title: 'Event Count per User', dataIndex: 'eventCountPerUser', key: 'eventCountPerUser' },
  ]; 

  const eventTableData = useMemo(() => {
	if (hestiaReport?.reports.eventReport) {
	  return getEventTableData(hestiaReport?.reports.eventReport);
	}
  }, [hestiaReport?.reports.eventReport]);

  var pageViewColumns: TableColumnsType<PageViewTableDataType> = [
	{ title: 'Page Name', dataIndex: 'pageName', key: 'pageName' },
	{ title: 'Page Views', dataIndex: 'screenPageViews', key: 'screenPageViews' },
	{ title: 'Total Users', dataIndex: 'totalUsers', key: 'totalUsers' },
	{ title: 'Page Views per User', dataIndex: 'screenPageViewsPerUser', key: 'screenPageViewsPerUser' },
  ]; 

  const pageViewTableData = useMemo(() => {
	if (hestiaReport?.reports.pageViewReport) {
	  return getPageViewTableData(hestiaReport?.reports.pageViewReport);
	}
  }, [hestiaReport?.reports.pageViewReport]);

  const overviewError = (hestiaReportError || powerBiDataError);
  const overviewLoading = (!authToken || !hestiaReport || hestiaReportLoading || consolidatedReportLoading || !consolidatedReport);

  return (
	<div className={`content-body report-body`}>
	  <Tabs tabIndex={-1}>
		<TabPane tab={"Overview"} key={1}>
		  { overviewError && (
		    <div className="report-error">
			  <FontAwesomeIcon icon={faExclamationTriangle} />
			  <span>{"Error: There was an issue trying to reach this data"}</span>
			</div>
		  )}
		  { !overviewError && overviewLoading && (
			<Loading />
	      )}
		  { !overviewError && !overviewLoading && (
			<div id="overview-report">
		      <h2>Overview</h2>
			  <Row className="report-row" style={{ display: "flex", justifyContent: "space-between", paddingTop: "15px"}}>
			    <Card className="metric-group" title={"Monthly Values"}>
			      <Card className="metric" size="small" title="Downloads">{consolidatedReport?.MonthlyDownloads}</Card>
			      <Card className="metric" size="small" title="Distinct Active Users">{consolidatedReport?.MonthlyActiveUsers}</Card>
				</Card>
			    <Card className="metric-group" title="Yearly Values">
				  <Card className="metric" size="small" title="Cumulative Active Users">{consolidatedReport?.YearlyCumulativeActiveUsers}</Card>
				</Card>
		        <Card className="metric-group" title="Total Global Users">
			      <Card className="metric" size="small" title="Total Users">{consolidatedReport?.TotalGlobalUsers}</Card>
				</Card>
			    <Card className="metric-group" title="Since App Launch">
			      <Card className="metric" size="small" title="Direct Lives Improved">{consolidatedReport?.DirectLivesImproved}</Card>
			      <Card className="metric" size="small" title="Indirect Lives Improved">{consolidatedReport?.IndirectLivesImproved}</Card>
				</Card>
			  </Row>
			</div>
		  )}
		</TabPane>
		<TabPane tab={"BrightSky Data"} key={2}>
		  { !authToken && (
		    <div className="report-error">
			  <FontAwesomeIcon icon={faExclamationTriangle} />
			  <span>{"Error: There was an issue trying to reach this data"}</span>
			</div>
		  )}
		  { authToken && (
  		    <div id="report" className="report-container">
	 		  <Report
			    key="workspace"
			    // embedType="Report"
		        tokenType="Aad"
			    accessToken={authToken!}
			    embedUrl={`https://app.powerbi.com/reportEmbed?reportId=676f4ec4-91ff-403b-b77a-fa5bf8e7ea26&groupId=307a95f0-504f-4c57-8358-1751e18bc334`}
			    embedId="676f4ec4-91ff-403b-b77a-fa5bf8e7ea26"
			    reportMode="View"
			    extraSettings={extraSettings}
			    permissions="All"
			  />
			  {!isFullscreen && <Button onClick={() => { toggleFullscreen(); }} type="text" className="expand" title="Maximize" icon={<FontAwesomeIcon icon={['far', 'expand']} />}></Button>}
			  {isFullscreen && <Button onClick={() => { toggleFullscreen(); }} type="text" className="expand" title="Minimize" icon={<FontAwesomeIcon icon={['far', 'compress']} />}></Button>}
	        </div>
		  )}
		</TabPane>
		<TabPane tab={"Hestia Data"} key={3}>
		  { hestiaReportError && (
		    <div className="report-error">
			  <FontAwesomeIcon icon={faExclamationTriangle} />
			  <span>{"Error: There was an issue trying to reach this data"}</span>
			</div>
		  )}
		  { !hestiaReportError && hestiaReportLoading && (
			<Loading />
		  )}
		  { !hestiaReportError && !hestiaReportLoading && (
		    <div id="hestia-report">
			  <div className="date-filter">
		        <h3>Time Period</h3>
			    <RangePicker
				  defaultValue={[startDate, endDate]}
				  onChange={(val) => {
				    setStartDate(val[0]);
				    setEndDate(val[1]);
				  }}
				  minDate={startDate ?? undefined}
				  maxDate={dayjs()}
				  allowClear={false}
			    />
			  </div>
			  <Tabs
				type="card"
				tabPosition="bottom"
			  >
	  		    <TabPane tab={"App Users"} key={0}>
		 	       <div className="hestia-data-tab">
				     <HestiaReportView 
				      report={hestiaReport?.reports.timePeriodReport} 
					  metricIndex={hestiaReport?.reports.timePeriodReport.metricHeaders.findIndex(x => x.name === "newUsers")} 
					  title="App Users"
			        />
				  </div>
			    </TabPane>
			    <TabPane tab={"Distinct Active Users"} key={1}>
			      <div className="hestia-data-tab">
				    <HestiaReportView 
				      report={hestiaReport?.reports.timePeriodReport} 
					  metricIndex={hestiaReport?.reports.timePeriodReport.metricHeaders.findIndex(x => x.name === "activeUsers")} 
					  title="Distinct Active Users"
			        />
				  </div>
			    </TabPane>
			    <TabPane tab={"Cumulative Active Users"} key={2}>
			      <div className="hestia-data-tab">
				    <HestiaReportView 
				      report={hestiaReport?.reports.timePeriodReport} 
					  metricIndex={hestiaReport?.reports.timePeriodReport.metricHeaders.findIndex(x => x.name === "sessions")} 
					  title="Cumulative Active Users"
			        />
				  </div>
			    </TabPane>
				<TabPane tab={"Events"} key={3}>
			      <div className="hestia-data-tab">
				    <Table
					  id="hestia-event-table"
					  size="small"
	  				  columns={eventColumns}
					  dataSource={eventTableData}
					  pagination={false}
				    />
				  </div>
			    </TabPane>
		        <TabPane tab={"Page Views"} key={4}>
			      <div className="hestia-data-tab">
				    <Table
					  id="hestia-page-view-table"
					  size="small"
	  				  columns={pageViewColumns}
					  dataSource={pageViewTableData}
					  pagination={false}
				    />
				  </div>
			    </TabPane>
			   {/* <TabPane tab={"Feedback"} key={4}>*/}
			   {/*   <div className="hestia-data-tab">*/}
				  {/*  <HestiaMultiMetricReportView */}
				  {/*    report={hestiaReport?.reports.feedbackReport} */}
					 {/* metricIndex={hestiaReport?.reports.feedbackReport.metricHeaders.findIndex(x => x.name === "eventCount")} */}
					 {/* dimensionIndexes={getFeedbackDimensionIndexes(hestiaReport?.reports.feedbackReport)} */}
					 {/* title="Feedback"*/}
			   {/*     />*/}
				  {/*</div>*/}
			   {/* </TabPane>*/}
			  </Tabs>
		    </div>
		  )}
		</TabPane>
	  </Tabs>
	</div>
  );
}


export default ReportPage;

