import { Button, Form, Input, Modal, Tabs } from "antd";

import { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Countries, Language, Resource, ResourceContent } from "../../models/countries-interface";
import RichTextEditor from "../../components/shared/RichTextEditor";
import { HttpPostResourceContent } from './../../models/countries-interface';
import { ResourceApi } from "../../apis/resource-api";

interface EditProps {
  onCancel: () => void;
  onSave: () => void;
  allResources: ResourceContent;
  country: Countries;
  resourceId: string;
  isNewResource: boolean;
}

const EditResourcePage = ({ onCancel, onSave, allResources, country, resourceId, isNewResource }: EditProps) => {

  // POST allCountryResources but only edit the resources prop
  const [formResources, setFormResources] = useState<{ [key: string]: Resource[] }>({
	...JSON.parse(JSON.stringify(allResources.resources)),
  });

  const languages = country.languages.iv;

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(country.languages.iv[0]);

  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [modal, modalContextHolder] = Modal.useModal();

  const [errors, setErrors] = useState<{ [key: string]: string }>({});//specifically error messages
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const onChangeTab = (key?: string) => {
    let lang = languages.find(l => l.code === key)
	if (!lang) {
	  throw Error("Bad Language Selected.");
	}
	if (lang !== selectedLanguage && lang) setSelectedLanguage(lang);
  }

  const setResourceProp = (val: Partial<Resource>) => {
	let rsc = formResources[selectedLanguage.code];
	let translationIndex = formResources[selectedLanguage.code].findIndex(x => x.id === resourceId);
	rsc[translationIndex] = { ...rsc[translationIndex], ...val };

    setFormResources({
	  ...formResources,
	  [selectedLanguage.code]: rsc,
	});
  };

  const getResourceProp = (key: keyof Resource) => {
	let resourceTranslation = formResources[selectedLanguage.code].find(x => x.id === resourceId);
	return resourceTranslation ? resourceTranslation[key]! : "";  // ts enforce not undefined
  };

  const validate = useCallback(() => {
	let err = false;
	let errs:{[key:string]:string} = {};

	for (let i = languages.length-1; i >= 0; i--) {
	  let lang = languages[i];
	  let resourceTranslation = formResources[lang.code].find(x => x.id === resourceId);
		
	  if (!resourceTranslation || resourceTranslation.title.trim().length === 0) {
	    err = true;
		if (touched[`${lang.code}-title`]) {
		  errs.title = `${lang.language} Title is required.`;
		}
	  }

	  if (!resourceTranslation || resourceTranslation.resourceUrl.trim().length === 0) {
	    err = true;
		if (touched[`${lang.code}-url`]) {
		  errs.url = `${lang.language} URL is required.`;
		}
	  }
	}

    setErrors(errs);

	if (Object.keys(errs).length > 0 && !isSubmitDisabled) setSubmitDisabled(true);
	if (Object.keys(errs).length === 0 && isSubmitDisabled) setSubmitDisabled(false);

	if (Object.keys(errs).length === 0 && touched.save) setTouched({ ...touched, save: false });

	return !err;
  }, [touched, formResources, isSubmitDisabled, languages, resourceId]);

  useEffect(() => {
    validate();
  }, [validate]);

  const pivotData:() => HttpPostResourceContent = () => {
	return { 
	  title: allResources.title,
	  binding: allResources.binding, 
	  country: allResources.country,
	  description: allResources.description,
	  resources: formResources,
	};
  }

  const submitAndSave = async () => {
    let all_touched = {
	  save: true,
	};

    for (let i = 0; i < languages.length; i++) {
	  let lang = languages[i].code;

	  all_touched = {
		...all_touched,
		[`${lang}-title`]: true,
		[`${lang}-url`]: true,
		[`${lang}-content`]: true,
	  };
	}

	setTouched(all_touched);

	if (validate()) {
	  setSubmitting(true);
	  await ResourceApi.updateResource(allResources.id, pivotData())
	    .then(() => {
	      onSave();
	    })
	    .catch((ex:any) => {
		  let data:string = ex.data || "";
		  if (data.includes(" already exists.")) {
			modal.error({
			  title: "Error",
			  content: <p>Squidex is busy right now. Please try again in a few seconds.</p>
			});
		  } else {
			modal.error({
			  title: "Error",
			  content: <p>{`An unexpected error occured: ${data}`}</p>
			});
		  }
		});

	  setSubmitting(false);
    } else {
	  setSubmitDisabled(true);
	}	
  };

  return (
    <>
	  <div className="content-header edit-header">
		<Button
	      icon={<FontAwesomeIcon icon={['far', 'angle-left']} />}
		  aria-label="Back to Resources"
		  onClick={onCancel}
		  className="page-title"
		  type="text"
		>
		  {'Back to Resources'}
		</Button>
		<Button
		  aria-label='Cancel'
		  onClick={onCancel}
		  className="row-right"
		  type="default"
		>
		  {'Cancel'}
		</Button>
		<Button
		  icon={<FontAwesomeIcon icon={['far', 'floppy-disk']} />}
		  aria-label='Save Changes'
		  onClick={() => { setConfirmOpen(true) }}
		  type="primary"
		  loading={isSubmitting}
		  disabled={isSubmitDisabled}
		>
		  {'Save Changes'}
		</Button>
		<Modal
		  title="Confirmation"
		  okText="Confirm"
		  onOk={() => {
		    setConfirmOpen(false);
			submitAndSave();
		  }}
		  onCancel={() => { setConfirmOpen(false) }}
		  open={isConfirmOpen}
		>
		  <p>
		    Please confirm that you would like to save these changes.
			By clicking save you are updating the live Bright Sky systems.
			Please ensure all information you have added is accurate and correct.
		  </p>
	    </Modal>
		{modalContextHolder}
	  </div>
	  <div className='content-body'>
		<Tabs 
		  className="resource-tabs" 
		  defaultActiveKey="1" 
		  items={languages.map((l) => ({ key: l.code, label: l.language }))} 
		  onChange={(k) => onChangeTab(k)} 
		/>			
		<Form>
		  <div className='content-tile'>
		    <Form.Item
			  validateStatus={errors.title ? "error" : "success"}
			  help={errors.title}
			>
			  <h3>{'Title'}</h3>
			  <Input
			    id="Title"
				placeholder="Resource Title"
				onBlur={() => setTouched({ ...touched, [`${selectedLanguage.code}-title`]: true })}
				value={getResourceProp('title')}
				onChange={(val) => {
				  setResourceProp({ title: val.target.value });
				}}
			  />
			</Form.Item>
			<Form.Item
			  validateStatus={errors.url ? "error" : "success"}
			  help={errors.url}
			>
			  <h3>{'URL'}</h3>
			  <Input
				id="URL"
				placeholder="Resource URL"
				onBlur={() => setTouched({ ...touched, [`${selectedLanguage.code}-url`]: true })}
				value={getResourceProp('resourceUrl')}
				onChange={(val) => {
				  setResourceProp({resourceUrl: val.target.value});
				}}
		      />
			  <div className='side-note'>
				<FontAwesomeIcon icon={['far', 'info-circle']} />
			    <span>{'Please note, only secure websites starting with "https" can be placed in Bright Sky.'}</span>
			  </div>
			</Form.Item>
			<Form.Item
			  key={`${selectedLanguage.code}-content`}//key is necessary because lang switching clears it
			  validateStatus={errors.content ? "error" : "success"}
			  help={errors.content}
			>
			  <h3>{'Content'}</h3>
			  <RichTextEditor
				placeholder="Resource Content"
				onBlur={()=> setTouched({ ...touched, [`${selectedLanguage.code}-content`]: true })}
				value={getResourceProp('content')}
				onChange={(val) => {
				if (val === getResourceProp('content')) return;
				  setResourceProp({ content: val });
				}}
		      />
			</Form.Item>
		  </div>
		</Form>
      </div>
	</>
  );
}

export default EditResourcePage;
