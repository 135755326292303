import localConfig from '../config/config.local.json';
import devConfig from '../config/config.dev.json';
import prodConfig from '../config/config.prod.json';
import { configModel } from './models/ConfigModel';

function isEnv(prefix: string) {
    const hostname = window.location.hostname;
    return hostname.indexOf(prefix) > -1;
};

export function getConfig() {
  const isLocal = isEnv('bslocal') || isEnv('bs-local') || isEnv('localhost');
  const isDev = isEnv('portal-dev.bright-sky.org');
  const isProd = isEnv('portal.bright-sky.org');

  if (isLocal) {
    return localConfig;
  } else if (isDev) {
    return devConfig;
  } else if (isProd) {
    return prodConfig;
  }
  return devConfig;
};

export const useConfig = (): configModel => {
    return getConfig() as configModel;
};