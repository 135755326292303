import { Button, Checkbox, CheckboxOptionType, Divider, Layout, Select, Spin } from "antd";

import { useMemo, useContext, useEffect, useState, useCallback } from "react";
import { AppContext } from "../../context/app-context";
import FeatureHeader from "../../components/shared/FeatureHeader";
import { SelectProps } from "antd/es/select";
import { CountrySelect, ODataTable } from "../../components";
import { ContentApi } from "../../apis/content-api";
import { HttpContentFile } from "../../models/content-interface";

import "./ContentExport.scss";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resolveAndDownloadBlob } from "../../utils/api";
import { AxiosResponse } from "axios";

const ContentExport = () => {
  const { isLoadingCountry, setLoadingCountry, token, selectedCountry, profile } = useContext(AppContext);

  const [ languageOptions, setLanguageOptions] = useState<CheckboxOptionType[]>([]);
  const [ selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  const [contentGroupOptions, setContentGroupOptions] = useState<CheckboxOptionType[]>([]);
  const [ selectedContentGroups, setSelectedContentGroups] = useState<string[]>([]);

  const [ isLoadingContentOptions, setLoadingContentOptions] = useState<boolean>(true);

  const [ isExportingDocs, setExportingDocs] = useState<boolean>(false);

  useEffect(() => {
    ContentApi.getContentTypes().then((data) => {
	  setContentGroupOptions(data.map(x => { return { label: x, value: x }; }));
	  setLoadingContentOptions(false);
	});
  }, []);
  
  const filteredContentGroupOptions = useMemo(() => {
	if (!selectedCountry || selectedCountry.code.iv !== "NZ") {
	  return contentGroupOptions;
	}

	// For NZ, filter out web-related options
	return contentGroupOptions
	  .filter(x => !(x.value === "Widget" || x.value === "Web Safety Guidance (Legal)" || x.value === "Submit Update" || x.value === "Web Privacy (Legal)"));
  }, [contentGroupOptions, selectedCountry]);

  const loadCountries = useCallback(() => {
    // setLoading(true);
	// httpPost(`Directory/Locations`, {country: c, isNationalHelplines: isNationalHelplines}, token, (d: any[]) => {
	//     setCountries(d);
	//     setLoading(false);
	// });'
		
	if (selectedCountry !== null) {
	  setLanguageOptions(selectedCountry.languages.iv.map((e) => {
		return {
		  label: e.language,
		  value: e.code,
		};
	  }));
	}

	setSelectedLanguages([]);
	setSelectedContentGroups(selectedContentGroups.filter(x => filteredContentGroupOptions.map(x => x.value).includes(x)));
  }, [selectedContentGroups, selectedCountry, filteredContentGroupOptions]);

  useEffect(() => {
	loadCountries();
	// should only reload once -- per selected country change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  const editLangs = (value: CheckboxValueType[]) => {
	setSelectedLanguages(value as string[]);
  }

  const allLangs = () => {
    if (selectedLanguages.length !== languageOptions.length) {
	  setSelectedLanguages(languageOptions?.map(x => x.value as string));
	} else {
	  setSelectedLanguages([]);
	}	
  }

  const editContentGroups = (value: CheckboxValueType[]) => {
	setSelectedContentGroups(value as string[]);
  }

  const allContentGroups = () => {
	if (selectedContentGroups.length !== filteredContentGroupOptions.length) {
	  setSelectedContentGroups(filteredContentGroupOptions.map(x => x.value as string));
	} else {
	  setSelectedContentGroups([]);
	}
  }


  const getFilterString = (): string => {
    if (selectedContentGroups.length > 0) {
	  let filter = selectedContentGroups.map(x => `FriendlyContentGroup eq '${x}'`);
	  return filter.join(' OR ');
	} else {
	  return `FriendlyContentGroup eq '__EMPTY__'`;
	}
  }

  return (
    <>
	  <div className="content-header">
	    <h2>Content Export</h2>
		<span className="row-right"></span>
		{ profile?.market === "All" && 
		  <>
		    <span className="row-right">Country / Market:</span>
		    <CountrySelect />
		  </>
	    }
		<Button
		  type="primary"
		  icon={<FontAwesomeIcon icon={['far', 'file-word']} />}
		  disabled={
		    isLoadingCountry || isLoadingContentOptions 
			|| !selectedCountry 
			|| selectedLanguages.length===0 
			|| selectedContentGroups.length===0
		  }
		  title={
		    selectedLanguages.length === 0 ? "Please select at least one language."
			  : (selectedContentGroups.length === 0 ? "Please select at least one content group." 
			  : undefined)
		  }
		  onClick={async () => {
			if (selectedCountry) {
			  setExportingDocs(true);
			  let proms: Promise<AxiosResponse>[] = [];

			  for (let lang in selectedLanguages) {
				for (let content in selectedContentGroups) {
				  proms.push(ContentApi.getExport(selectedCountry.code.iv, selectedLanguages[lang], selectedContentGroups[content]));
				}
			  }

			  await Promise.allSettled(proms)
			    .then((res) => {
				  resolveAndDownloadBlob(res, selectedCountry.code.iv);
				});

			  setExportingDocs(false);
			}
	      }}
		  loading={isExportingDocs}
		>
		  {"Export Documents"}
		</Button>
	  </div>
	  <div className="content-body">
		<div className="content-tile content-export-tile">	
		  <div>
		    <div className="row">
			  <h3>Language:</h3>
			  <Button className="row-right" onClick={allLangs}>{selectedLanguages.length !== languageOptions.length ? "All Languages" : "No Languages"}</Button>
			</div>
			{ !isLoadingCountry ? 
			  <Checkbox.Group
			    onChange={editLangs}
			    options={languageOptions}
				value={selectedLanguages}
			  /> 
			  : 
			  <Spin />
		    }
		  </div>		
		  <div>
		    <div className="row">
			  <h3>Content Group:</h3>
			  <Button className="row-right" onClick={allContentGroups}>
			    {selectedContentGroups.length !== filteredContentGroupOptions.length ? "All Content Groups" : "No Content Groups"}
		      </Button>
			</div>
			{ !isLoadingContentOptions ? 
		      <Checkbox.Group
			    onChange={editContentGroups}
				value={selectedContentGroups}
				options={filteredContentGroupOptions}
			  /> 
			  : 
			  <Spin />
		    }
		  </div>	
		  {/* <ODataTable<HttpContentFile>
		    className="content-table"
			rowKey={(x)=>x.Id}
			columns={[
			  { key: "FriendlyContentGroup", label: "Content Group", sortable: false },
			  { key: "ContentType", label: "Content Type", sortable: false },
			  { key: "FriendlyContentName", label: "Content Name", sortable: false}
			]}
			pageSize={4}
			filterLiteral={getFilterString()}
			query={async (qs)=>{
		      return ContentApi.getContentFiles(qs).then((res)=>{
			    return {
				  count: res.count,
				  entries: res.entries
				};
			  }).catch(()=>{
			    return {
				  count: 0,
				  entries: []
				};
			  });
			}}
		  /> */}
		</div>
	  </div>
	</>
  )
}

export default ContentExport;
