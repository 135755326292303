import axios from 'axios';
import { getConfig } from '../config/functions';

const getHeaders = ((token) => {
  return {
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    }
  }
})

const appConfig = getConfig();

export const httpPost = (url, data, token) => {
  return axios
    .post(appConfig.apiUrl + url, data, getHeaders(token))
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));     
}   

export const httpGet = (url, token) => {
  return axios
    .get(appConfig.apiUrl + url, getHeaders(token))
    .then((res) => res.data)    
    .catch((error) => Promise.reject(error)); 
}   

// const api = axios.create({
//     baseURL: appConfig.apiUrl,
    // httpsAgent: new https.Agent({
    //   rejectUnauthorized: false,
    // }),
  // });
  
// api.interceptors.request.use(async (config) => {
//   config.headers = {
//     Accept: 'application/json',
//     'Access-Control-Allow-Origin': '*',
//   };
//    /* eslint-disable no-param-reassign */
//     // config.headers.withCredentials = true;
//     // config.headers.Accept = 'application/json';
//     // config.headers['Access-Control-Allow-Origin'] = '*';
//     // config.headers['X-CSRF'] = 1;
//   //   config.headers.Authorization: 'Bearer ' + token
  
//     return config;
//   });
  
  // api.interceptors.response.use(
  //   response => response,
  //   (error) => {
  //     if (error.response && (error.response.status === 401 || error.response.status === 403)) {
  //       window.history.pushState({}, '', '/');
  //     }
  //     return Promise.reject(error);
  //   },
  // );
  