import { MainLayout } from '../components';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReportPage from './reports';
import DirectoryWrapper from './directories/DirectoryWrapper';
import NationalHelplineWrapper from './directories/NationalHelplineWrapper';
import ResourcesPage from './resources/ResourcesPage';
import ContentExportPage from './content-export/ContentExport';

 
  const AppRoutes = () => {
    return (
        <>
            <MainLayout>
                <Routes>
                    <Route path="/" Component={ReportPage} />
                    <Route path="content-export" Component={ContentExportPage} />
                    <Route path="service-directory" Component={DirectoryWrapper} />
                    <Route path="national-helplines" Component={NationalHelplineWrapper} />
                    <Route path="resources" Component={ResourcesPage} />
                </Routes>
            </MainLayout>
        </>
    );
};

export default AppRoutes;
