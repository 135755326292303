import { useContext } from "react";
import { useMsal } from '@azure/msal-react';
import { Button, Dropdown, MenuProps, Space } from "antd";

import "./TopNav.scss";
import { AppContext, GetDisplayName } from "../../context/app-context";
import { DownOutlined } from "@ant-design/icons";




function TopNav () {
	const { isLoadingCountry: loading } = useContext(AppContext);
	const { instance } = useMsal();
	const Signout = () => {
		instance.logoutRedirect();
	}

	const items: MenuProps['items'] = [
		{
		  key: '2',
		  label: "Sign Out",
		  icon: null,
		  onClick: ()=>{
			Signout();
		  }
		},
	  ];

    return (
		<div className="header row">
			<div className="logo" />
			<div className="row-right">
				<Dropdown className="user-dropdown" menu={{ items }}>
					<h2>
						{loading ? (<></>) : GetDisplayName()}
						<DownOutlined />
					</h2>	
				</Dropdown>
			</div>
			
		</div>
    );
}

export default TopNav;
