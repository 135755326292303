import { Button, Form, Input, InputNumber, Modal, Popconfirm, Radio, Switch, Tabs } from "antd";

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Countries, HttpPostDirectory, Language } from "../../models/countries-interface";
import RichTextEditor from "../../components/shared/RichTextEditor";
import { useConfig } from "../../config/functions";

import GoogleMapReact from 'google-map-react';
import { DirectoryApi } from "../../apis/directory-api";

interface EditProps {
  onCancel: () => void;
  onSave: () => void;
  directory: HttpPostDirectory;
  country: Countries;
  isNationalHelplines: boolean;
}

const EditServiceDirectoryPage = ({ onCancel, onSave, country, directory, isNationalHelplines }: EditProps) => {

  const [formDirectory, setFormDirectory] = useState<HttpPostDirectory>(directory);

  const languages = country.languages.iv;

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(languages[0]);
  const [isConfirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [modal, modalContextHolder] = Modal.useModal();

  const [errors, setErrors] = useState<{[key:string]:string}>({});//specifically error messages
  const [touched, setTouched] = useState<{[key:string]:boolean}>({});
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const [mapMarker, setMapMarker] = useState<any>();
  const [locationProxy, setLocationProxy] = useState<any>(null);

  const [coordHelper, setCoordHelper] = useState<string>("");
  const [coordHelperErr, setCoordHelperErr] = useState<string|null>(null);
  const [coordHelperLoading, setCoordHelperLoading] = useState<boolean>(false);

  const config = useConfig();

  useEffect(()=>{
    validate();
  }, [formDirectory, touched]);

  useEffect(()=>{
	if(locationProxy)onDragPin();
  }, [locationProxy]);

  const validate = ()=>{
    let err = false;
	let errs:{[key:string]:string} = {};

	for (let i = languages.length-1; i >= 0; i--) {
	  let lang = languages[i];
			
	  if (formDirectory.name[lang.code].trim().length === 0) {
		err = true;
		if (touched[`${lang.code}-name`]) {
		  errs.name = `${lang.language} Name is required.`;
		}
	  }
	  if (formDirectory.hours[lang.code].replace(/<(.|\n)*?>/g, '').trim().length === 0) {
		err = true;
		if (touched[`${lang.code}-hours`]) {
		  errs.hours = `${lang.language} Hours is required.`;
		}
	  }
	  if (formDirectory.description[lang.code].replace(/<(.|\n)*?>/g, '').trim().length === 0) {
		err = true;
		if (touched[`${lang.code}-description`]) {
		  errs.description = `${lang.language} Description is required.`;
		}
	  }
	  if (formDirectory.displayOnMap.iv) {
		if (formDirectory.address[lang.code].replace(/<(.|\n)*?>/g, '').trim().length === 0) {
		  err = true;
		  if (touched[`${lang.code}-address`]) {
		    errs.address = `${lang.language} Address is required.`;
		  }
		}
	  }
	}

	if (formDirectory.emailAddresses.iv.length > 0) {
	  let isPrimary = formDirectory.emailAddresses.iv.filter(x => x.isPrimary);
	  if (isPrimary.length !== 1) {
		err = true;

	    if (touched.emails) {
		  errs.emails = "One Email Address must be primary if there is one or more Email Addresses."
		}
	  }
	}
	for (let i in formDirectory.emailAddresses.iv) {
	  let addr = formDirectory.emailAddresses.iv[i];
	  if (addr.email.trim().length === 0) {
		err = true;
		if (touched.save) {
		  errs.emails = "No empty Email Addresses."
		}
	  }
	}

    if (formDirectory.phoneNumbers.iv.length > 0) {
	  let isPrimary = formDirectory.phoneNumbers.iv.filter(x => x.isPrimary);
	  if (isPrimary.length !== 1) {
		err = true;

		if (touched.phones) {
		  errs.phones = "One Phone Number must be primary if there is one or more Phone Numbers."
		}
	  }
	}
	for (let i in formDirectory.phoneNumbers.iv) {
	  let addr = formDirectory.phoneNumbers.iv[i];
	  if (addr.number.trim().length === 0) {
		err = true;
		if (touched.save) {
		  errs.phones = "No empty Phone Numbers."
		}
	  }
	}

    if (formDirectory.displayOnMap.iv) {
	  if (formDirectory.location.iv.latitude===0 && formDirectory.location.iv.longitude === 0) {
		err = true;
		if (touched.latitude && touched.longitude) {
		  errs.coordinates = `Coordinates are required.`;
		}
	  }
	}

	if (JSON.stringify(errs) !== JSON.stringify(errors)) setErrors(errs);

	if (Object.keys(errs).length > 0 && !isSubmitDisabled) setSubmitDisabled(true);
	if (Object.keys(errs).length === 0 && isSubmitDisabled) setSubmitDisabled(false);

	if (Object.keys(errs).length === 0 && touched.save) setTouched({ ...touched, save: false });

    return !err;
  };


  const onChangeTab = (key: string) => {
    let lang = languages.find(l => l.code === key)
	if (!lang) throw "Bad language selection.";
	if (lang !== selectedLanguage) setSelectedLanguage(lang);
  }

  const onDragPin = ()=>{
	setFormDirectory({
	  ...formDirectory,
	  location: { iv: { 
		latitude: locationProxy.lat, 
		longitude: locationProxy.lng,
	  }}
	});
  };

  const submitAndSave = async () => {
    let all_touched = {
	  save: true,
	  website: true,
	  emails: true,
	  phones: true,
	  displayOnMap: true,
	  latitude: true,
	  longitude: true,
	};

	for(let i = 0; i < languages.length; i++){
	  let lang = languages[i].code;
			
	  all_touched = {
	    ...all_touched, 
		[`${lang}-name`]: true,
		[`${lang}-hours`]: true,
		[`${lang}-description`]: true,
		[`${lang}-address`]: true,
	  };							
	}
	setTouched(all_touched);

	if (validate()) {
	  setSubmitting(true);
	  await DirectoryApi.updateDirectory(formDirectory)
	    .then(() => {
		  onSave();
	    })
	    .catch((ex:any) => {
		  let data:string = ex.data || "";
		  if (data.includes(" already exists.")){
		    modal.error({
		      title: "Error",
		      content: <p>Squidex is busy right now. Please try again in a few seconds.</p>
		    });
		  } else {
		    modal.error({
		      title: "Error",
			  content: <p>{`An unexpected error occured: ${data}`}</p>
		    });
	 	  }
	    });

	  setSubmitting(false);
	} else {
	  setSubmitDisabled(true);
	}
  };

  const backToLabel = `Back to ${isNationalHelplines ? "National Helplines" : "Service Directory"}`;

  return (
	<>
      <div className="content-header edit-header">
		<Button
	      icon={<FontAwesomeIcon icon={['far', 'angle-left']} />}
		  aria-label={backToLabel}
		  onClick={onCancel}
		  className="page-title"
		  type="text"
		>
		  {backToLabel}
	    </Button>
		<Button
	      aria-label='Cancel'
		  onClick={onCancel}
		  className="row-right"
		  type="default"
		>
		  {'Cancel'}
		</Button>
		<Button
	      icon={<FontAwesomeIcon icon={['far', 'floppy-disk']} />}
		  aria-label='Save Changes'
		  onClick={()=>{setConfirmOpen(true)}}
		  type="primary"
		  loading={isSubmitting}
		  disabled={isSubmitDisabled}
		>
		  {'Save Changes'}
		</Button>
		<Modal
	      title="Confirmation"
		  okText="Confirm"
		  onOk={()=>{
			setConfirmOpen(false);
		    submitAndSave();
		  }}
		  onCancel={()=>{setConfirmOpen(false)}}
		  open={isConfirmOpen}
		>
		  <p>
			Please confirm that you would like to save these changes. 
			By clicking save you are updating the live Bright Sky systems. 
			Please ensure all information you have added is accurate and correct.
		  </p>
		</Modal>
		{modalContextHolder}
	  </div>
	  <div className="content-body edit-directory-body">
		<Tabs 
		  defaultActiveKey="1" 
	      items={languages.map((l) => ({ key: l.code, label: l.language }))} 
		  onChange={(k) => {
			onChangeTab(k);
		  }} 
		/>
		<Form>
		  <div className="col tile-group">
			<div className="content-tile">
			  <Form.Item
			    validateStatus={errors.name ? "error" : "success"}
				help={errors.name}
			  >
			    <h3>{'Name'}</h3>
			    <Input
				  id="Name"
				  placeholder="Location Name"
			      value={formDirectory.name[selectedLanguage.code]}
				  onBlur={()=>setTouched({...touched, [`${selectedLanguage.code}-name`]: true })}
			      onChange={(val) => {
					setFormDirectory({
					  ...formDirectory,
					  name: {
					    ...formDirectory.name,
						[selectedLanguage.code]: val.target.value,
					  },
					});
				  }}
				/>
			  </Form.Item>
			  <Form.Item
				validateStatus={errors.hours ? "error" : "success"}
				help={errors.hours}
			  >
				<h3>{'Hours'}</h3>
				<RichTextEditor
			      key={`${selectedLanguage.code}-hours`}
				  placeholder="Location Hours"
				  value={formDirectory.hours[selectedLanguage.code]}
				  onBlur={() => {
				    setTouched({...touched, [`${selectedLanguage.code}-hours`]: true })
				  }}
				  onChange={(val) => {
				    if (val === formDirectory.hours[selectedLanguage.code]) {
				  	  return;
					}
					setFormDirectory({
		  			  ...formDirectory,
					  hours: {
				  	    ...formDirectory.hours,
						[selectedLanguage.code]: val,
					  },
					});
				  }}
			    />
			  </Form.Item>
			  <Form.Item
				validateStatus={errors.description ? "error" : "success"}
		  	    help={errors.description}
			  >
				<h3>Description</h3>
				<RichTextEditor
				  key={`${selectedLanguage.code}-description`}
			      id="Description"
				  placeholder="Location Description"
				  value={formDirectory.description[selectedLanguage.code]}
				  onBlur={()=>setTouched({...touched, [`${selectedLanguage.code}-description`]: true })}
				  onChange={(val)=>{
					if (val === formDirectory.description[selectedLanguage.code]) {
					  return;
		 			}
					setFormDirectory({
					  ...formDirectory,
					  description: {
					    ...formDirectory.description,
						[selectedLanguage.code]: val,
					  },
					});
				  }}
				/>
		      </Form.Item>
			</div>
			<div className="content-tile">
			  <Form.Item
				validateStatus={errors.Website ? "error" : "success"}
			    help={errors.Website}
		      >
				<h3>{'Website'}</h3>
				<Input
				  id="Website"
				  placeholder="Location Website"
				  onBlur={()=>setTouched({...touched, website: true })}
				  value={formDirectory.website.iv}
				  onChange={(val) => {
					setFormDirectory({
					  ...formDirectory,
					  website: { iv: val.target.value },
					});
				  }}
			    />
			  </Form.Item>
			  <Form.Item
				validateStatus={errors.emails ? "error" : "success"}
			    help={errors.emails}
			  >
				<Radio.Group 
				  onChange={(e) => {
					setTouched({...touched, emails: true });

					let arr = formDirectory.emailAddresses.iv;
					arr = arr.map(x => { return {email: x.email, isPrimary: false}; });
					arr[e.target.value].isPrimary = true;
					setFormDirectory({
					  ...formDirectory,
					  emailAddresses: { iv: arr },
					});
			      }}
				  value={(()=>formDirectory.emailAddresses.iv.findIndex((x)=>x.isPrimary))()} 
				>
				  <div className="row input-table-row input-table-header">
					<div><h3>Primary</h3></div>
					<div><h3>Email Address</h3></div>
				  </div>
				  { formDirectory.emailAddresses.iv.map((e, i) => (
					<div key={i} className="row input-table-row input-table-entry">
					  <div>
						<Radio className="input-table-radio" value={i} />
					  </div>
					  <div>
					    <Input
						  placeholder="Email Address"
						  onBlur={() => setTouched({ ...touched, emails: true })}
						  value={e.email}
						  onChange={(val) => {
							let arr = formDirectory.emailAddresses.iv;
							arr[i].email = val.target.value;
							setFormDirectory({
							  ...formDirectory,
							  emailAddresses: { iv: arr },
							});
						  }}
						/>
					  </div>
					  <div>
				        <Popconfirm
						  overlayClassName="ExhibitorRepsTable-popConfirm"
						  okText="Yes"
						  cancelText="No"
						  title={`Are you sure you want to remove this Email Address?`}
						  onConfirm={() => {
							setTouched({...touched, emails: true });

							let arr = formDirectory.emailAddresses.iv;
							arr.splice(i, 1);
							setFormDirectory({
						      ...formDirectory,
							  emailAddresses: { iv: arr },
							});
						  }}
						  placement="right"
						>
				          <Button
						    type="text"
							className="hiddenButton"
							icon={<FontAwesomeIcon icon={['far', 'trash']} />}
						  />
						</Popconfirm>
				      </div>
					</div>
				  ))}
				</Radio.Group>
				<Button
				  aria-label='Add Email Address'
				  onClick={() => {
					setTouched({ ...touched, emails: true });

					let arr = formDirectory.emailAddresses.iv;
					arr.push({
				      isPrimary: arr.length === 0,
					  email: "",
		  			});
					setFormDirectory({
				      ...formDirectory,
					  emailAddresses: { iv: arr },
					});
				  }}
				  className="content-btn"
				  type="text"
				  icon={<FontAwesomeIcon icon={['far', 'plus']} />}
				>
				  {'Add Email Address'}
				</Button>
			  </Form.Item>
			  <Form.Item
				validateStatus={errors.phones ? "error" : "success"}
				help={errors.phones}
			  >
				<Radio.Group 
				  onChange={(e) => {
					setTouched({...touched, phones: true });

					let arr = formDirectory.phoneNumbers.iv;
					arr = arr.map(x => { return {number: x.number, isPrimary: false}; });
					arr[e.target.value].isPrimary = true;
					setFormDirectory({
					  ...formDirectory,
					  phoneNumbers: { iv: arr },
					});
				  }}
				  value={(()=>formDirectory.phoneNumbers.iv.findIndex((x)=>x.isPrimary))()} 
				>
				  <div className="row input-table-row input-table-header">
					<div><h3>Primary</h3></div>
					<div><h3>Phone Number</h3></div>
				  </div>
				  { formDirectory.phoneNumbers.iv.map((e, i) => (
					<div key={i} className="row input-table-row input-table-entry">
				      <div>
						<Radio className="input-table-radio" value={i} />
					  </div>
					  <div>
					    <Input
						  placeholder="Phone Number"
						  onBlur={() => setTouched({ ...touched, phone: true })}
						  value={e.number}
						  onChange={(val) => {
							setTouched({...touched, phones: true });

							let arr = formDirectory.phoneNumbers.iv;
							arr[i].number = val.target.value;
							setFormDirectory({
						      ...formDirectory,
							  phoneNumbers: { iv: arr },
							});
						  }}
						/>
					  </div>
					  <div>
						<Popconfirm
						  overlayClassName="ExhibitorRepsTable-popConfirm"
						  okText="Yes"
						  cancelText="No"
						  title={`Are you sure you want to remove this Phone Number?`}
						  onConfirm={() => {
							setTouched({ ...touched, phones: true });

							let arr = formDirectory.phoneNumbers.iv;
							arr.splice(i, 1);
						      setFormDirectory({
								...formDirectory,
								phoneNumbers: { iv: arr },
							  });
							}}
						  placement="right"
						>
						  <Button
							type="text"
							className="hiddenButton"
							icon={<FontAwesomeIcon icon={['far', 'trash']} />}
						  />
						</Popconfirm>
					  </div>
					</div>
				  ))}
				</Radio.Group>
				<Button
				  aria-label='Add Phone Number'
				  onClick={() => {
					setTouched({ ...touched, phones: true });

					let arr = formDirectory.phoneNumbers.iv;
					arr.push({
				      isPrimary: arr.length === 0,
					  number: "",
		  			});
					setFormDirectory({
					  ...formDirectory,
					  phoneNumbers: { iv: arr },
					});
				  }}
				  className="content-btn"
				  type="text"
				  icon={<FontAwesomeIcon icon={['far', 'plus']} />}
				>
				  {'Add Phone Number'}
				</Button>
			  </Form.Item>
			</div>
			{ !isNationalHelplines && 
			  <div className="row directory-location-tiles">
			    <div className="col">
				  <div className="content-tile">
				    <Form.Item
					  validateStatus={errors.address ? "error" : "success"}
					  help={errors.address}
				    >
					  <h3>{'Address'}</h3>
					  <RichTextEditor
					    key={`${selectedLanguage.code}-address`}
					    placeholder="Location Address"
					    value={formDirectory.address[selectedLanguage.code]}
					    onBlur={() => setTouched({ ...touched, [`${selectedLanguage.code}-address`]: true })}
					    onChange={(val) => {
						  if (val === formDirectory.address[selectedLanguage.code]) {
						    return;
						  }
						  setFormDirectory({
					        ...formDirectory,
						    address: {
							  ...formDirectory.address,
							  [selectedLanguage.code]: val,
					        },
						  });
				        }}
					  />
				    </Form.Item>
				    <Form.Item>
				      <h3>{'Display On Map'}</h3>
					  <Switch 
					    style={{ backgroundColor: formDirectory.displayOnMap.iv ? "green" : "red" }}
					    checked={formDirectory.displayOnMap.iv}
				        onChange={(val) => {
					  	  setFormDirectory({
						    ...formDirectory,
						    displayOnMap: { iv: val }
						  });
					    }} 
					  />
					  <span style={{ paddingLeft: "5px" }} className="fieldValue">
					    {formDirectory.displayOnMap.iv ? "On" : "Off"}
 					  </span>
					  <div className='side-note'><FontAwesomeIcon icon={['far', 'info-circle']} />
					    <span>It is imperative you keep services safe by only displaying them on the map if it is safe to do so.  It is the markets responsibility to ensure they do not display services on the map that are meant to be hidden.</span>
					  </div>
				    </Form.Item>
				  </div>
				  <div className="content-tile">								
				    <Form.Item
					  validateStatus={errors.coordinates ? "error" : "success"}
					  help={errors.coordinates}
				    >
					  <div className="row coord-inputs">
				        <div>
						  <h3>{'Latitude'}</h3>
						  <InputNumber
					        step={0.0001}
						    placeholder="Latitude"
						    onBlur={() => setTouched({ ...touched, latitude: true })}
						    value={formDirectory.location.iv.latitude}
					        onChange={(val) => {
						  	  setFormDirectory({
							    ...formDirectory,
						        location: { iv: { 
							      latitude: val ?? 0, 
						  		  longitude: formDirectory.location.iv.longitude,
						        }}
							  });
							  mapMarker.setPosition({lat: val ?? 0, lng: formDirectory.location.iv.longitude});
						    }}
						  />
					    </div>
				        <div>
						  <h3>{'Longitude'}</h3>
						  <InputNumber
						    step={0.0001}
						    placeholder="Longitude"
						    onBlur={() => setTouched({ ...touched, longitude: true })}
						    value={formDirectory.location.iv.longitude}
						    onChange={(val) => {
						      setFormDirectory({
							    ...formDirectory,
							    location: { iv: { 
							  	  latitude: formDirectory.location.iv.latitude,
								  longitude: val ?? 0, 
							    }}
							  });
							  mapMarker.setPosition({lat: formDirectory.location.iv.latitude, lng: val ?? 0});
						    }}
						  />
					    </div>
					  </div>
				    </Form.Item>			
				    <div>
					  <Form.Item
					    validateStatus={coordHelperErr ? "error" : "success"}
					    help={coordHelperErr || undefined}
				 	  >
					    <h3>{'Coordinate Helper'}</h3>
					    <div className="row coord-inputs">
						  <Input
						    placeholder="Exact Address"
						    value={coordHelper}
						    onChange={(val) => {
							  setCoordHelperErr(null);
							  setCoordHelper(val.target.value)
						    }}
						  />
						  <Button 
						    type="primary"
					        loading={coordHelperLoading}
						    onClick={async () => {
							  if (coordHelper.length === 0) {
							    setCoordHelperErr("You must enter an address.");
							    return;
							  }
							  setCoordHelperErr(null);
							  setCoordHelperLoading(true);
							  let http_response = await fetch(`${config.googleMapsGeocodeUrl}?address=${coordHelper.trim().split(" ").join("+")}&key=${config.googleMapsKey}`)
							    .catch((e) => {
						          setCoordHelperErr("Something went wrong with the connection.");
							    });
													
							  let body = await http_response?.json();
							  if (body.results.length > 0) {
							    let loc = body.results[0].geometry.location;

							    mapMarker.setPosition(loc);
							    mapMarker.map.setCenter(loc);
							    mapMarker.map.setZoom(16);
							    setFormDirectory({
								  ...formDirectory,
								  location: { iv: {
								    latitude: loc.lat,
								    longitude: loc.lng,
								  }}
							    });

							    setCoordHelper("");//erase on success
							  } else {
							    setCoordHelperErr("No locations matching that address.");
							  }

							  setCoordHelperLoading(false);
						    }}
					 	  >
						    {'Set Coordinates via Address'}
						  </Button>
				        </div>
				      </Form.Item>
				      <div className='side-note'><FontAwesomeIcon icon={['far', 'info-circle']} />
				        <span>The Coordinate Helper is a form-only, temporary and private tool to help you approximate this location.  You can further refine the approximation by dragging the pin on the Location Map or by manually inputting the coordinates into the Latitude and Longitude fields.</span>
					  </div>
				    </div>			
				  </div>
			    </div>
			    <div className="content-tile">
			      <div>
				    <h3>{'Location'}</h3>
				    <div className="google-map">
				      <GoogleMapReact
					    bootstrapURLKeys={{ key: config.googleMapsKey }}
					    defaultCenter={{lat: directory.location.iv.latitude, lng: directory.location.iv.longitude}}
					    defaultZoom={16}
					    yesIWantToUseGoogleMapApiInternals
					    onGoogleApiLoaded={({map, maps}) => {										
						  let marker = new maps.Marker({
		  				    map,
			 			    position: {lat: formDirectory.location.iv.latitude, lng: formDirectory.location.iv.longitude},
						    draggable: true,
					      });
					      setMapMarker(marker);

					      marker.addListener('dragend', (e:any)=>{
						    setLocationProxy({lat: e.latLng.lat(), lng: e.latLng.lng()});
						  });
					    }}
				 	  >
					    {/* <div lat={formDirectory.location.iv.latitude} lng={formDirectory.location.iv.longitude}>
					      <FontAwesomeIcon icon={['far', 'location-dot']} className='map-pin' />
					    </div> */}
					  </GoogleMapReact>
				    </div>
				  </div>
			    </div>
			  </div>
		    }
		  </div>
		</Form>
	  </div>
	</>
  );
}

export default EditServiceDirectoryPage;
