import React, { useContext } from "react";
import { useLocation, Link } from 'react-router-dom';
import { Layout, Menu } from "antd";

import "./SideNav.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "../../context/app-context";

function SideNav() {


	const { profile } = useContext(AppContext);

	let { pathname } = useLocation();
	pathname = pathname.split('/')[1];

	return (
		<div className="nav-container">
			<Link to=""><div className={pathname === "" || pathname === "home" ? 'nav-button selected' : 'nav-button'}>
				<FontAwesomeIcon icon={[pathname === "" || pathname === "home" ? 'fas' : 'far', "home"]} />
				<span>Home</span>
			</div></Link>
			{profile?.market !== "US" && <>
				<Link to="/service-directory"><div className={pathname === "service-directory" ? 'nav-button selected' : 'nav-button'}>
					<FontAwesomeIcon icon={[pathname === "service-directory" ? 'fas' : 'far', "database"]} />
					<span>Service Directory</span>
				</div></Link>
				<Link to="/national-helplines"><div className={pathname === "national-helplines" ? 'nav-button selected' : 'nav-button'}>
					<FontAwesomeIcon icon={[pathname === "national-helplines" ? 'fas' : 'far', "headset"]} />
					<span>National Helplines</span>
				</div></Link>
			</>}
			<Link to="/resources"><div className={pathname === "resources" ? 'nav-button selected' : 'nav-button'}>
				<FontAwesomeIcon icon={[pathname === "resources" ? 'fas' : 'far', "circle-info"]} />
				<span>Resources</span>
			</div></Link>
			<Link to="/content-export"><div className={pathname === "content-export" ? 'nav-button selected' : 'nav-button'}>
				<FontAwesomeIcon icon={[pathname === "content-export" ? 'fas' : 'far', "folder-grid"]} />
				<span>Content Export</span>
			</div></Link>
			<Link to="https://launcher.myapps.microsoft.com/api/signin/d003f9eb-0d1f-42bb-8889-c7f6e28b9364?tenantId=c3876b00-e2ec-4573-939b-60119db29dea" target="_blank"><div className='nav-button'>
				<FontAwesomeIcon icon={['far', "database"]} />
				<span>Help Desk</span>
			</div></Link>
		</div>
	);
}

export default SideNav;


