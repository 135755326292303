import { InteractionType } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import React, { ReactNode } from "react"
import { useState } from "react";
import { loginRequest } from '../auth/authConfig';
import { Profile } from "../models/profile-interface";
import { Countries } from "../models/countries-interface";

interface Props {
    children?: ReactNode
}

type TApp = {
    profile: Profile | null,
    setProfile: Function,
    pageTitle: string | null,
    setPageTitle: Function,
    countries: Countries[] | null,
    setCountries: Function,
    token: string | null,
    setToken: Function,
    isLoadingCountry: boolean,
    setLoadingCountry: Function,
    selectedCountry: Countries | null,
    setSelectedCountry: Function,
}

const AppContext = React.createContext<TApp>({
    profile: null,
    setProfile: () => { },
    pageTitle: null,
    setPageTitle: () => { },
    countries: null,
    setCountries: () => { },
    token: null,
    setToken: () => { },
    isLoadingCountry: true,
    setLoadingCountry: () => { },
    selectedCountry: null,
    setSelectedCountry: () => { },
});

enum Rights {
    // TODO - add rights
    // CanViewMyHolds = "CanViewMyHolds",
    // CanViewAllHolds = "CanViewAllHolds",
    // CanEditHolds = "CanEditHolds",
};

function HasRight(rights: Rights[]) {
    const { profile } = React.useContext(AppContext);

    if (profile && profile.Rights) {
        return profile.Rights.some((x: any) => Object.values(rights).includes(x as any));
    }

    return false;
}

function ProfileHasRight(profile: Profile, rights: Rights[]) {
    if (profile && profile.Rights) {
        return profile.Rights.some((x: any) => Object.values(rights).includes(x as any));
    }

    return false;
}

function GetDisplayName() {
    const { profile } = React.useContext(AppContext);
    const { instance } = useMsal();

    if (profile?.FirstName && profile.LastName) {
        return `${profile.LastName}, ${profile?.FirstName} `;
    }

    return instance.getActiveAccount()?.name;
}

function GetUserId() {
    const { profile } = React.useContext(AppContext);
    const { instance } = useMsal();

    if (profile?.UserId) {
        return profile.UserId;
    }

    return instance.getActiveAccount()?.username;
}

function IsAuthenticated() {
    return useIsAuthenticated();
}

function Signin() {
    var results = useMsalAuthentication(InteractionType.Redirect, loginRequest);

    return { error: results.error, isAuthenticated: IsAuthenticated() };
}

function AppContextProvider({ children }: Props) {
    const [profile, setProfile] = useState(null);
    const [pageTitle, setPageTitle] = useState(null); 
    const [countries, setCountries] = useState(null);
    const [token, setToken] = useState(null);
    const [isLoadingCountry, setLoadingCountry] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);

    return (
        <AppContext.Provider value={{
            profile,
            setProfile,
            pageTitle,
            setPageTitle,
            countries,
            setCountries,
            token,
            setToken,
            isLoadingCountry,
            setLoadingCountry,
            selectedCountry,
            setSelectedCountry
        }}>
            {children}
        </AppContext.Provider>
    )
}

export { AppContextProvider, AppContext, HasRight, ProfileHasRight, Rights, GetDisplayName, GetUserId, Signin, IsAuthenticated }
