import axios, { AxiosError, AxiosResponse, Canceler } from 'axios';
import { getConfig } from '../config/functions';
import JSZip from 'jszip';

const appConfig = getConfig();

/**
 * Timeout in milliseconds defaults to 15 seconds
 */
const DEFAULT_TIMEOUT = 1000 * 15;

/**
 * Timeout in milliseconds defaults to 20 minutes
 */
const UPLOAD_FILE_TIMEOUT = 1000 * 60 * 20;

/**
 * Post Timeout in milliseconds defaults to 5 minute
 * temporary for import prev agenda endpoint 
 */
const POST_TIMEOUT = 1000 * 60 * 5;

const instance = axios.create({
    baseURL: appConfig.apiUrl + '/',
    timeout: DEFAULT_TIMEOUT,
    withCredentials: false,
});

const cancelToken = axios.CancelToken;

const responseBody = (response: AxiosResponse) => response.data;
const errorBody = (error: AxiosError) => {
	throw (error.response || {});
};
const fileResponse = (response: AxiosResponse) => response.data
const fileResponseBody = (response: AxiosResponse) => Buffer.from(response.data, "binary").toString("base64");

export let cancel: Canceler;


const getHeaders = ((token:string) => {
	return {
		"Access-Control-Allow-Origin": "https://localhost:3000",
		'Authorization': `Bearer ${token}`
	}
});

var _token = "NULL";

export const api = {

	setApiToken(token:string) {
		//console.log(token)
		_token = token;
	},

    get: (url: string, token:string = _token, timeoutMs: number = UPLOAD_FILE_TIMEOUT) => instance.get(url, {  headers: { 'Access-Control-Allow-Origin': 'https://localhost:3000', 'Authorization': `Bearer ${token}`, 'Content-type': 'application/json' }, cancelToken: new cancelToken((c) => cancel = c), timeout: timeoutMs }).then(responseBody),
    post: (url: string, body: {}, timeoutMs: number = POST_TIMEOUT) => instance.post(url, body, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json' }, cancelToken: new cancelToken((c) => cancel = c), timeout: timeoutMs }).then(responseBody).catch(errorBody),
    put: (url: string, body: {}, timeoutMs: number = DEFAULT_TIMEOUT) => instance.put(url, body, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json' }, timeout: timeoutMs }).then(responseBody),
    delete: (url: string, body?: {}, timeoutMs: number = DEFAULT_TIMEOUT) => instance.delete(url, { data: body, headers: { 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json' }, timeout: timeoutMs }).then(responseBody),
    patch: (url: string, body: {}, timeoutMs: number = DEFAULT_TIMEOUT) => instance.patch(url, body, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json' }, timeout: timeoutMs }).then(responseBody),
    /**
     * for patching with data and file
     * @param url 
     * @param body 
     * @param apiVersion 
     * @returns response data
     */
    patchMultiPart: (url: string, body: {}, timeoutMs: number = UPLOAD_FILE_TIMEOUT) => instance.patch(url, body, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data' }, timeout: timeoutMs }).then(responseBody),
    /**
     * for posting with data and file
     * @param url 
     * @param body 
     * @param apiVersion 
     * @returns response data
     */
    postMultiPart: (url: string, body: {}, timeoutMs: number = UPLOAD_FILE_TIMEOUT) => instance.post(url, body, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data' }, timeout: timeoutMs }).then(responseBody),
    /**
     * for putting with data and file
     * @param url 
     * @param body 
     * @param apiVersion 
     * @returns response data
     */
    putMultiPart: (url: string, body: {}, timeoutMs: number = UPLOAD_FILE_TIMEOUT) => instance.put(url, body, { headers: { 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data' }, timeout: timeoutMs }).then(responseBody),
    /**
     * get file from server
     * @param url 
     * @param apiVersion 
     * @returns base64
     */
    getFile: (url: string, timeoutMs: number = UPLOAD_FILE_TIMEOUT):Promise<AxiosResponse<File>> => {
		return instance.get(
			url, 
			{ 
				responseType: 'arraybuffer', 
				headers: { 'Access-Control-Allow-Origin': '*' }, 
				cancelToken: new cancelToken((c) => cancel = c), 
				timeout: timeoutMs 
			}
		);
	},
    /**
     * get file from server
     * @param url 
     * @param apiVersion 
     * @returns array buffer
     */
    getFileBuffer: (url: string, timeoutMs: number = UPLOAD_FILE_TIMEOUT) => instance.get(url, { responseType: 'arraybuffer', headers: { 'Access-Control-Allow-Origin': '*' }, cancelToken: new cancelToken((c) => cancel = c), timeout: timeoutMs }).then(responseBody),
    /**
     * get file from server using a post request
     * @param url
     * @param body
     * @param apiVersion 
     * @returns array buffer
     */
    postGetFile: (url: string, body: {}, timeoutMs: number = UPLOAD_FILE_TIMEOUT) => instance.post(url, body, { responseType: 'arraybuffer', headers: { 'Access-Control-Allow-Origin': '*' }, cancelToken: new cancelToken((c) => cancel = c), timeout: timeoutMs }).then(fileResponse)
};

// either zip and download or download a single file based on content export response
export const resolveAndDownloadBlob = function (results: PromiseSettledResult<AxiosResponse<any>>[], market: string) {
    if (results.length === 0 || results[0].status !== "fulfilled") {
        return;
    }

    const downloadLink = document.createElement('a');

    if (results.length <= 1) {
        const file_name: string[] = results[0].value.headers['content-name'].split(".");
        var formattedFileName = file_name[0] + `_${new Date().toLocaleDateString()}.` + file_name[1];
        formattedFileName = formattedFileName.replaceAll('/', '-');

        const url = window.URL.createObjectURL(new Blob([results[0].value.data]));
        downloadLink.href = url;
        downloadLink.setAttribute('download', formattedFileName);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        window.URL.revokeObjectURL(url);
        downloadLink.remove();

        return;
    }

    var zip = new JSZip();

    results.forEach((exportResponse: PromiseSettledResult<AxiosResponse<File>>) => {
      if (exportResponse.status !== "fulfilled") {
          return;
      }
      const file_name: string[] = exportResponse.value.headers['content-name'].split(".");
      var formattedFileName = file_name[0] + `_${new Date().toLocaleDateString()}.` + file_name[1];
      formattedFileName = formattedFileName.replaceAll('/', '-');
      const file_language = exportResponse.value.headers['language'];
      if (!file_language || file_language === null) {
          zip.file(formattedFileName, exportResponse.value.data);
          return;
      } else {
          var lang_folder = zip.folder(file_language); 
          if (lang_folder) {
              lang_folder.file(formattedFileName, exportResponse.value.data);
          }
      }
    })

    zip.generateAsync({
        type: "blob"
    })
        .then(function (content) {
            downloadLink.download = "folder" + new Date().getTime();
            var url = URL.createObjectURL(content);
            downloadLink.href = url;
            downloadLink.innerHTML = "download " + downloadLink.download;
            downloadLink.setAttribute('download', `${market}_content_export-${new Date().toLocaleDateString()}`);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            window.URL.revokeObjectURL(url);
            downloadLink.remove();
        });
}