import { Configuration, LogLevel, PopupRequest } from "@azure/msal-browser";
import { getConfig } from '../config/functions';

const appConfig = getConfig();

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: appConfig.REACT_APP_AD_CLIENT_ID || "",
        authority: appConfig.REACT_APP_AD_AUTHORITY || "",
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [appConfig.REACT_APP_AD_RECORDS_SCOPE || ""]
};
