import React, { useMemo, useState, useCallback } from 'react';
import type { TableColumnsType } from 'antd';
import { Button, Row, Table } from 'antd';
import { GAReportResponse } from './ReportPage';
import dayjs, { Dayjs } from 'dayjs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, TimeScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ColumnType } from 'antd/es/table';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';

interface SummaryDataType {
  key: React.Key;
  [year: string]: string | number | React.Key | Dayjs,
  total: number;
}

interface DataType extends SummaryDataType {
  month: string;
  monthValue: number,
  fullDate: Dayjs,
}

interface ExpandedDataType {
  key: React.Key;
  date: string;
  dateValue: number;
  [year: string]: string | number | React.Key,
  total: number;
}

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, TimeScale);

export const HestiaReportView: React.FC<{ report: GAReportResponse | undefined, metricIndex: number | undefined, title: string }> =
  ({ report, title, metricIndex }) => {
  const [yearColumns, setYearColumns] = useState<TableColumnsType<DataType | ExpandedDataType>>([]);

  const sortedReport: GAReportResponse | null = useMemo(() => {
    if (!report) {
      return null;
    }

    report.rows.sort(function(a, b) {
      return a.dimensionValues[0].value.localeCompare(b.dimensionValues[0].value);
    });

    return report;
  }, [report]);

  const columns: TableColumnsType<DataType> = useMemo(() => {
    if (!sortedReport) {
      return [];
    }

    var workingColumns: TableColumnsType<DataType> = [
      { title: 'Month', dataIndex: 'month', key: 'month', width: 100 }
    ]; 

    var yearColumns: TableColumnsType<DataType> = [];
    sortedReport.rows.forEach((row) => {
      var yearStr = dayjs(row.dimensionValues[0].value).year().toString();
      if (yearColumns.findIndex(x => x.title === yearStr) === -1) {
        yearColumns.push({ title: yearStr, dataIndex: `year-${yearStr}`, key: `year-${yearStr}` });
      }
    });

    workingColumns = workingColumns.concat(yearColumns);
    setYearColumns(yearColumns as TableColumnsType<DataType | ExpandedDataType>);

    workingColumns.push({ title: 'Total', dataIndex: 'total', key: 'total' });

    return workingColumns;
  }, [sortedReport]);

  const tableData: DataType[] = useMemo(() => {
    if (!sortedReport || metricIndex === undefined) {
      return [];
    }
    
    var data: DataType[] = [];

    sortedReport.rows.sort(function(a, b) {
      return a.dimensionValues[0].value.localeCompare(b.dimensionValues[0].value);
    });

    // for each date record
    sortedReport.rows.forEach((row, index) => {
      var fullDate = dayjs(row.dimensionValues[0].value);
      var month = fullDate.month();
      var year = fullDate.year();

      var existingIndex = data.findIndex(x => x.monthValue === month)
      if (existingIndex > -1) {
        (data[existingIndex][`year-${year}`] as number) += parseInt(row.metricValues[metricIndex]?.value);
        data[existingIndex].total += parseInt(row.metricValues[metricIndex]?.value);
      } else {
        data.push({
          key: index.toString(),
          month: fullDate.format("MMMM"),
          monthValue: month,
          [`year-${year}`]: parseInt(row.metricValues[metricIndex]?.value),
          fullDate: fullDate,
          total: parseInt(row.metricValues[metricIndex]?.value),
        });
      }
    });

    return data;
  }, [sortedReport, metricIndex]);

  const expandedColumns: TableColumnsType<ExpandedDataType> = useMemo(() => {
    if (!sortedReport) {
      return [];
    }

    var workingColumns: TableColumnsType<ExpandedDataType> = [
      { title: null, dataIndex: 'date', key: 'date', width: 100 }
    ]; 
     
    workingColumns = workingColumns.concat(
      (yearColumns as TableColumnsType<ExpandedDataType>).map((column) => {
        var { title, ...columnWithoutTitle } = column;
        return {
          title: null,
          ...columnWithoutTitle,
        };
      })
    );

    workingColumns.push({ title: null, dataIndex: 'total', key: 'total' });
    return workingColumns;
  }, [sortedReport, yearColumns]);

  const expandedRowRender = useCallback((record: DataType) => {
    if (!sortedReport || metricIndex === undefined) {
      return [];
    }

    var data: ExpandedDataType[] = [];

    // for each date record
    sortedReport.rows.forEach((row) => {
      var fullDate = dayjs(row.dimensionValues[0].value);
      var date = fullDate.date();
      var month = fullDate.month();
      var year = fullDate.year();

      // filter data to relevant expanded row / by month
      if (month !== record.monthValue) {
        return;
      }

      var existingIndex = data.findIndex(x => x.dateValue === date)
      if (existingIndex > -1) {
        (data[existingIndex][`year-${year}`] as number) += parseInt(row.metricValues[metricIndex].value);
        data[existingIndex].total += parseInt(row.metricValues[metricIndex].value);
      } else {
        data.push({
          key: `${row.dimensionValues[0].value}`,
          date: fullDate.format("D"),
          dateValue: date,
          [`year-${year}`]: parseInt(row.metricValues[metricIndex].value),
          total: parseInt(row.metricValues[metricIndex].value),
        });
      }
    });

    return <Table columns={expandedColumns as TableColumnsType<ExpandedDataType>} dataSource={data} pagination={false} />;
  }, [expandedColumns, sortedReport, metricIndex]);

  const summaryData: SummaryDataType | null = useMemo(() => {
    if (!tableData || !yearColumns) {
      return null;
    }

    var summaryData: SummaryDataType = {
      key: "summary",
      total: 0,
    };

    yearColumns.forEach((yearColumn: ColumnType<DataType | ExpandedDataType>) => {
      summaryData[yearColumn.dataIndex as keyof DataType] = 
        tableData.map(x => x[yearColumn.dataIndex as keyof DataType] as number).reduce(
          (accumulator, currentValue) => accumulator + currentValue);
    });

    summaryData.total = tableData.map(x => x.total).reduce((accumulator, currentValue) => accumulator + currentValue);

    return summaryData;
  }, [tableData, yearColumns]);

  // custom plugin to support legend fit / padding between legend and chart
  const plugin = {
    id: "legend-plugin",
    beforeInit(chart: any) {
      // Get a reference to the original fit function
      const origFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        origFit.bind(chart.legend)();
        // Change the height to any desired value
        this.height += 20;
      }
    }
  }

  return (
    <>
      <h3 className="hestia-report-header">{title}</h3>
      <div className="monthly-bar-chart-container">
        <Bar
          data={{
            labels: tableData.map(x => x.fullDate.format("MMM YYYY")),
            datasets: [
              {
                label: 'United Kingdom',
                data: tableData.map(x => x.total),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
              },
            ],
          }}
          plugins={[ChartDataLabels, plugin]}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              y: {
                display: false
              }
            },
            plugins: {
              datalabels: {
                display: true,
                anchor: 'end',
                align: 'top',
                font: {
                  weight: 'bold'
                }
              },
              tooltip: {
                callbacks: {
                  title: function (context) {
                    return dayjs(context[0].label).format("MMMM YYYY");
                  },
                }
              },
            }
          }}
        />
      </div>
      <Row className="hestia-report-container">
        <div>
          <Bar
            className="yearly-bar-chart"
            data={{
              labels: [yearColumns.map(x => x.title)],
              datasets: [
                {
                  label: 'United Kingdom',
                  data: yearColumns.map((x: ColumnType<DataType | ExpandedDataType>) => {
                     return summaryData ? summaryData[x.dataIndex as keyof SummaryDataType] : 0
                  }),
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
              ],
            }}
            plugins={[ChartDataLabels, plugin]}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                datalabels: {
                  display: true,
                  anchor: 'end',
                  align: 'top',
                  font: {
                    weight: 'bold'
                  }
                },
              },
              scales: {
                y: {
                  display: false
                }
              },
            }}
          />
        </div>
        <div>
          <Table
            columns={columns}
            expandable={{ 
              expandedRowRender, 
              defaultExpandedRowKeys: [],
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <Button 
                    size="small" 
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)}
                    icon={<FontAwesomeIcon icon={faMinus} />}
                  />
                ) : (
                  <Button 
                    size="small" 
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => onExpand(record, e)}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                  />
                )
            }}
            dataSource={tableData}
            size="small"
            pagination={false}
            summary={() => {
              if (!summaryData) {
                return;
              }

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} /> {/* Always blank, expand btn column */}
                    <Table.Summary.Cell index={1} /> {/* Always blank, "month" column */}
                    { yearColumns.map((yearColumn: ColumnType<DataType | ExpandedDataType>, index) => {
                      return (
                        <Table.Summary.Cell index={index + 2} key={"summary-" + yearColumn.dataIndex + "-cell"}>
                          <b>{summaryData[yearColumn.dataIndex as keyof SummaryDataType] as number}</b>
                        </Table.Summary.Cell>
                      );
                    })}
                    <Table.Summary.Cell index={yearColumns.length + 3}>
                      <b>{summaryData.total}</b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
      </Row>
    </>
  );
};