import { useContext, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { AppContext, Signin } from './context/app-context';
import { Error } from './components';
import { AppRoutes } from './features';
import { loginRequest } from './auth/authConfig';
import { httpGet } from './common/api';
import { Profile } from './models/profile-interface';
import { api } from './utils/api';

import "./fontawesome";
import './App.scss';

function App() {
  const { error, isAuthenticated } = Signin();
  const { instance } = useMsal();

  const [attemptLoad, setAttemptLoad] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingCountries] = useState(false);
  const [profileError] = useState(false);
  const { profile, setProfile, countries, setCountries, setSelectedCountry, token, setToken, isLoadingCountry: loading, setLoadingCountry: setLoading } = useContext(AppContext);

  useEffect(() => {
    if (isAuthenticated && attemptLoad === false) {
	  const account = instance.getAllAccounts()[0];
	  setLoadingProfile(false);
	  setAttemptLoad(true);
	  instance.acquireTokenSilent({
	    ...loginRequest,
		account: account,
	  })
	    .then((msalResponse) => {
	      api.setApiToken(msalResponse.accessToken);
		  if (!token) {
		    setToken(msalResponse.accessToken);
		  }
		  if (!profile) {
		    setLoading(true);
		    httpGet('Auth/CurrentUser', msalResponse.accessToken)
			  .then((p: Profile) => {
				setProfile(p);

				setLoadingProfile(false);

				if (!countries) {
			      httpGet('Content/Countries', msalResponse.accessToken)
				    .then((data:any) => {
 					  setCountries(data);
					  if (p.market === "All") {
		     		    setSelectedCountry(data[0]);
				      } else {
					    setSelectedCountry(data.find((c:any) => c.code && c.code.iv === p.market));
					  }
					  setLoading(false);
			        });
				}
			  })
			  .catch((error) => { console.log(error)});
		  }		
		});
	}

	if (loadingProfile || loadingCountries) {
	  setLoading(true);
	} else {
	  setLoading(false);
	}
  }, [isAuthenticated, attemptLoad, setProfile, setCountries, token, loadingProfile, loadingCountries, instance, profile, setToken, setLoading, countries, setSelectedCountry]);

  return (
	<>
	  {(isAuthenticated === false) && <>Loading...</>}
	  {isAuthenticated && !loading && !(error || profileError) &&
	    <>
		  <AppRoutes />
		</>
	  }
	  {(error || profileError) && <Error />}
	</>
  );
}

export default App;
