import { useState } from 'react';
import { httpGet } from '../../../common/api';

const useTokenContext = () => {
  const [token, setToken] = useState<string>();

  const tokenCallback = (t: any, callback: Function) => {
    setToken(t);
    callback(t);
  }
  const useInitializeToken = (callback: Function) => {
    if (!token) {
      httpGet('Report/AuthToken', null)
        .then((res) => 
          tokenCallback(res, callback)
        )
        .catch((error) => console.log(error));
    } 
  };

  return { token, useInitializeToken };
};

export default useTokenContext;
