import { HttpPostDirectory } from "../models/countries-interface";
import { api } from "../utils/api";

export const DirectoryApi = {
  getServices: async (country: string, isNationalHelplines: boolean): Promise<any> => {
    return await api.post(`Directory/Locations`, {country, isNationalHelplines});
  },

  updateDirectory: async (directory: HttpPostDirectory): Promise<void> => {
    await api.post(`Directory/Update`, directory);
  },

  deleteService: async (id: string): Promise<any> => {
    return await api.delete(`Directory/${id}`);
  }
};