import { Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { Countries } from "../../models/countries-interface";
import { AppContext } from "../../context/app-context";

import "./CountrySelect.scss";

interface ICountrySelectProps {
	countryFilter?: string[];
}

const CountrySelect = ({countryFilter=[]}: ICountrySelectProps) => {
	const [selectableCountries, setSelectableCountries] = useState<any[]>();
	const [prevSelectedCountry, setPrevSelectedCountry] = useState<Countries>();
	const { profile, countries, isLoadingCountry: loading, selectedCountry, setSelectedCountry } = useContext(AppContext);

	const { Option } = Select;

	const onChangeCountry = ((country: string) => {
		let sc = countries?.find(c => c.code && c.code.iv === country);
		setSelectedCountry(sc);
	})

	useEffect(() => {
		if (countries && profile && profile.market) {
			let country = profile.market;
			let userCountry = null;
			let language: string = "en";
			let countryName = '';
			if (!selectedCountry) {
				if (country === "All") {
					userCountry = countries[0];
				} else {
					userCountry = countries.find(c => c.code && c.code.iv === profile.market);
				}
				countryName = userCountry?.name ? [language] ? userCountry.name[language] : '' : '';
				if (countryName)
					setSelectedCountry(userCountry);
			}
			if (!selectableCountries) {
				if (country === "All") {
					const mappedItems = countries.map((v, i) => {
						if(v.name[language]==="Ghana")return;
						if (!countryFilter.includes(v.name[language])) {
							return (
								<Option key={i.toString()} value={v.code.iv}>
									{(v.name[language])}
								</Option>
							);
						}
					});
					setSelectableCountries(mappedItems);
				}
			}
			if (selectedCountry && (!prevSelectedCountry || selectedCountry.code?.iv !== prevSelectedCountry.code?.iv)) {
				setPrevSelectedCountry(selectedCountry);
			}
		}
	}, [profile, countries, selectedCountry, selectableCountries, loading, prevSelectedCountry]);

	return (
		<>
			<Select
				className="country-select"
				onChange={onChangeCountry}
				value={selectedCountry?.code.iv}
				allowClear={false}
				loading={!selectableCountries || selectableCountries.length===0}
			>
				{selectableCountries}
			</Select>
		</>
	)
}

export default CountrySelect;

