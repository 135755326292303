import { Login } from '../models/auth-interface';
import { HttpContentFiles } from '../models/content-interface';
import { api } from '../utils/api';
import { AxiosResponse } from 'axios';

export const ContentApi = {
	getContentFiles: async (qs:string): Promise<HttpContentFiles> => {
		let odata_result = await api.get(`odata/ContentFiles${qs}`);
		return {
			count: odata_result['@odata.count'],
			entries: odata_result.value
		};
	},
	getContentTypes: async (): Promise<string[]> => {
		return await api.get(`Content/GroupLabels`);
	},
	getExport: async (country: string, lang: string, content: string): Promise<AxiosResponse<File>> => {
		var file = await api.getFile(`Export/${country}/${lang}/${content}`);
		file.headers.language = lang;
		return file;
	},
};