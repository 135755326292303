import { FC } from 'react';
import { Spin } from 'antd';

export const Loading: FC<{className?: string}> = ({ className }) => {
  return (
    <div className={className + " loading"} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin size="default" />
    </div>
  );
};
